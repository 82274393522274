import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row, Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import dateFormats from '../UI/FormatDate/formatDate';
import Loader from '../App/Loader';
import config from '../../config/config';
import fetchMethodRequest from '../../config/service';
import { Link } from 'react-router-dom';
import CountDisplay from './components/CountDisplay';
import { updateCkEditorValues } from '../../config/configData';

const Dashboard = () => {
  const { t } = useTranslation('common');

  const [dashboardData, setDashboardData] = useState({});
  const [recentMatchedProperties, setRecentMatchedProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reminders, setReminders] = useState([]);
  const [todoData, setTodoData] = useState([]);
  const [news, setNews] = useState([]);

  useEffect(() => {
    getCountData();
    matchedProperties();
    getReminders();
    getTodoList();
    getNews();
  }, []);

  let user = JSON.parse(localStorage.getItem('loginCredentials'))

  const getCountData = () => {
    fetchMethodRequest('GET', `dashboard/getAgentCounts?email=${user.email}`)
      .then(async (response) => {
        if (response) {
          setDashboardData(response);
        }
      }).catch((err) => {
        return err;
      });
  }

  const matchedProperties = () => {
    fetchMethodRequest('GET', `recentMatchedProperties`)
      .then(async (response) => {
        if (response && response.recentmatchedproperties && response.recentmatchedproperties.length > 0) {
          response.recentmatchedproperties.forEach((item) => {
            item.addedDate = dateFormats.formatDate(item.addedDate, config.dateDayMonthFormat);
          })
          setRecentMatchedProperties(response.recentmatchedproperties);
        }
      }).catch((err) => {
        return err;
      });
  }

  const getReminders = () => {
    fetchMethodRequest('GET', `reminders`)
      .then(async (response) => {
        if (response && response.reminders && response.reminders.length > 0) {
          setReminders(response.reminders);
        }
      }).catch((err) => {
        return err;
      });
  }

  const dateAndTimeFormat = (timestamp) => {
    if (timestamp) {
      const date = new Date(timestamp);
      let hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, '0');
      let period = 'AM';

      if (hours >= 12) {
        period = 'PM';
        // Convert 12-hour format to 12 AM/PM format
        if (hours > 12) {
          hours -= 12;
        }
      }
      // Format the time as "hh:mm AM/PM"
      const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes} ${period}`;
      let newDate = dateFormats.formatDate(timestamp, config.dateDayMonthFormat)
      return <span title={formattedTime}>
        {newDate + ' ' + formattedTime}
      </span>;
    }
  }

  const getTodoList = () => {
    fetchMethodRequest('GET', `toDoLists`)
      .then(async (response) => {
        if (response && response.todolists && response.todolists.length > 0) {
          setTodoData(response.todolists);
        }
      }).catch((err) => {
        return err;
      });
  }

  const getNews = () => {
    fetchMethodRequest('GET', `news`)
      .then(async (response) => {
        if (response && response.news && response.news.length > 0) {
          setNews(response.news);
        }
      }).catch((err) => {
        return err;
      });
  }

  return (
    <Container className="dashboard ml-5" style={{ width: '98%' }}>
      <Loader loader={isLoading} />

      <Row>
        <Col md={12}>
          <h3 className="page-title mb-1 fw-bold">{t('DashBoard')}</h3>
        </Col>
      </Row>

      {/* Counts */}
      <CountDisplay dashboardData={dashboardData} />

      <div>
        <Row>
          <Col lg={6} xl={6} >
            <Card className="dashBoardCardWithScroll">
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">{'Recent Matched Properties'}</h5>
                </div>
                <Table className="table--bordered table--head-accent table-fixed" responsive hover>
                  <thead>
                    <tr style={{ display: 'flex' }}>
                      <th style={{ flex: '0 0 7%' }}>{'S.No'}</th>
                      <th style={{ flex: '0 0 23%' }}>{'Client Name'}</th>
                      <th style={{ flex: '0 0 32%' }}>{'Search Name'}</th>
                      <th style={{ flex: '0 0 20%' }}>{'Property Count'}</th>
                      <th style={{ flex: '0 0 18%', paddingLeft: '50px' }}>{'Date'}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {recentMatchedProperties?.length > 0 ? (
                      <>
                        {recentMatchedProperties.slice(0, 5).map((item, i) => {
                          let count = item?.addedProperties?.length;

                          return (
                            <tr key={i} style={{display: 'flex' }}>
                              <td style={{ flex: '0 0 7%', textAlign: 'center' }}>{i + 1}</td>
                              <td style={{ flex: '0 0 23%', textAlign: 'left' }}>
                                {item.clientName}
                              </td>
                              <td style={{ flex: '0 0 36%', paddingLeft: '10px' }} title={item.searchName}>{item.searchName}</td>
                              <td style={{ flex: '0 0 15%', textAlign: 'center' }}>
                                <Link
                                  to="/showMatchedProperties"
                                  state={{ propertyID: item._id, isDashboard: true }}
                                  style={{ color: '#0e4768', fontWeight: 'bold' }}
                                >
                                  {count}
                                </Link>
                              </td>
                              <td style={{ flex: '0 0 18%', textAlign: 'center' }}>{item.addedDate}</td>
                            </tr>
                          );
                        })}
                      </>
                    ) : (
                      <tr className="bold-text">
                        <td colSpan="5">
                          No Recent Properties
                        </td>
                      </tr>
                    )}
                  </tbody>
                  {recentMatchedProperties?.length > 0 ?
                    < div className="d-flex justify-content-end pt-2 pe-4">
                      <Link to="/RecentMatchedProperties">...More</Link>
                    </div>
                    : null}
                </Table>
              </CardBody>
            </Card>
          </Col>

          <Col lg={6} xl={6} >
            <Card className="dashBoardCardWithScroll">
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">{'Reminders'}</h5>
                </div>
                <Table className="table--bordered table--head-accent table-fixed" responsive hover>
                  <thead>
                    <tr style={{display: 'flex' }}>
                      <th style={{ flex: '0 0 7%' }}>{'S.No'}</th>
                      <th style={{ flex: '0 0 24%' }}>{'Title'}</th>
                      <th style={{ flex: '0 0 24%' }}>{'Client Name'}</th>
                      <th style={{ flex: '0 0 27%' }}>{'Description'}</th>
                      <th style={{ flex: '0 0 18%', paddingLeft: '45px' }}>{'Date'}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reminders?.length > 0 ? (
                      <>
                        {reminders.slice(0, 5).map((item, i) => {
                         let clientName = item.clientName?.firstName;
                          return (
                            <tr key={i} style={{display: 'flex' }}>
                              <td style={{ flex: '0 0 5%', textAlign: 'center' }}>{i + 1}</td>
                              <td style={{ flex: '0 0 26%', paddingLeft: '28px' }} title={item.title}>{item.title}</td>
                              <td style={{ flex: '0 0 24%', paddingLeft: '15px' }} title={clientName}>{clientName}</td>
                              <td style={{ flex: '0 0 25%' }} title={item.description}>{item.description}</td>
                              <td style={{ flex: '0 0 20%', textAlign: 'center' }}>{dateAndTimeFormat(item.date)}</td>
                            </tr>
                          );
                        })}
                      </>
                    ) : (
                      <tr className="bold-text">
                        <td colSpan="5">No Data</td>
                      </tr>
                    )}
                  </tbody>
                  {reminders?.length > 0 ?
                    < div className="d-flex justify-content-end pt-2 pe-4">
                      <Link to="/reminders">...More</Link>
                    </div>
                    : null}
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <div className='mt-2'>
        <Row>
          <Col lg={6} xl={6} >
            <Card className="dashBoardCardWithScroll">
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">{'To Do Lists'}</h5>
                </div>
                <Table className="table--bordered table--head-accent table-fixed" responsive hover>
                  <thead>
                    <tr style={{ display: 'flex' }}>
                      <th style={{ flex: '0 0 10%' }}>{'S.No'}</th>
                      <th style={{ flex: '0 0 65%' }}>{'Text'}</th>
                      <th style={{ flex: '0 0 25%' }}>{'Status'}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {todoData?.length > 0 ? (
                      <>
                        {todoData.slice(0, 5).map((item, i) => {
                          return (
                            <tr key={i} style={{ display: 'flex' }}>
                              <td style={{ flex: '0 0 10%', paddingLeft: '20px' }}>{i + 1}</td>
                              <td style={{ flex: '0 0 65%', textAlign: 'left' }} title={item.text}>
                                {item.text}
                              </td>
                              <td style={{ flex: '0 0 25%', textAlign: 'left' }}>{item.status}</td>
                            </tr>
                          );
                        })}
                      </>
                    ) : (
                      <tr className="bold-text">
                        <td colSpan="5">
                          No Data
                        </td>
                      </tr>
                    )}
                  </tbody>
                  {todoData?.length > 0 ?
                    < div className="d-flex justify-content-end pt-2 pe-4">
                      <Link to="/toDoLists">...More</Link>
                    </div>
                    : null}
                </Table>
              </CardBody>
            </Card>
          </Col>
          <Col lg={6} xl={6} >
            <Card className="dashBoardCardWithScroll">
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">{'News'}</h5>
                </div>
                {news?.length > 0 ? (
                  <div className="news-item-container">
                    {news.slice(0, 5).map((item, i) => {
                      let des = item.description ? updateCkEditorValues(item.description) : '';
                      return (
                        <div className='news-item d-flex align-items-start' key={i}>
                          <div className='news-content'>
                            <h5><b>{item.title}</b></h5>
                            <p>{des}</p>
                          </div>
                          {item.images ? (
                            <div className='news-image'>
                              <img src={`${config.imgUrl}news/${item.images}`} alt='' />
                            </div>
                          ) : null}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <tr className="bold-text d-flex text-center">
                    <td colSpan="4">No Recent News</td>
                  </tr>
                )}
                {news?.length > 0 ?
                  <div className="d-flex justify-content-end pt-2 pe-4">
                    <Link to="/news">...More</Link>
                  </div>
                  : null}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

Dashboard.propTypes = {
  t: PropTypes.func.isRequired,
};

export default Dashboard;
