import { Badge,  Col, Row, Card, CardBody, CardHeader, Collapse, ButtonGroup, ButtonToolbar } from 'reactstrap';
import moment from 'moment';
import { Link,Navigate  } from 'react-router-dom';
import '../../../scss/dashboardStyles.css';
import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { Calendar } from 'primereact/calendar';
import FilterOperations from './FilterOperations';
import { Paginator } from 'primereact/paginator';
import { CSVLink } from "react-csv";
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { withTranslation } from 'react-i18next';
import { MultiSelect } from 'primereact/multiselect';
import { AutoComplete } from 'primereact/autocomplete';

//Modals
import ShowHideColumnsModal from '../CommonModals/ShowHideColumnsModal';
import FormModal from '../../Form/FormModal';
import DeleteRowModal from '../CommonModals/DeleteRowModal';
import ViewModal from '../CommonModals/viewModal';
import BulkUploadModal from '../CommonModals/BulkUploadModal';
import ConfirmationModal from '../CommonModals/ConfirmationModal';
import SessionExpiryModal from '../CommonModals/SessionexpiryModal';
import PreviewModal from '../CommonModals/PreviewModal';
import SendEmailModal from '../CommonModals/SendEmailModal';
import DeleteFilterModal from '../CommonModals/DeleteFilterModal';
import ExportToCsvModal from '../CommonModals/ExportToCsvModal';
import AddNotesModal from '../CommonModals/AddNotesModal';

// fetch method for server call
import fetchMethodRequest from '../../../config/service';
import config from '../../../config/config';
import configImages from '../../../config/configImages';
import configMessages from '../../../config/configMessages';
import bulkSampleFiles from '../../../config/configSampleFiles'
import apiCall from '../../../config/apiCalls';
//Render Date picker
import RenderIntervalDatePickerField from '../../../shared/components/form/IntervalDatePicker';

// Toaster message
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';

// Date Formate
import dateFormats from '../../UI/FormatDate/formatDate';
//Loader
import Loader from '../../App/Loader';
//store
import store from '../../App/store'
import validate from '../../Validations/validate'
import { reduxForm, } from 'redux-form'
import { connect } from 'react-redux'
import crypto from "crypto-js";

import PaginatorComponent from './PaginatorComponent';
import { element } from 'prop-types';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCog, faPrint, faGripHorizontal, faList } from "@fortawesome/free-solid-svg-icons";
import { Button } from 'primereact/button';
import { formatIndianCurrency, formatPropType } from '../../../utils/utils';
import FunnelFilter from './FunnelFilter';
import RolePermissions from "../CommonModals/Permissions";
import ResizePanel from "react-resize-panel";
import DataTableEditor from './DataTableEditor';
import FileGalleryView from './FileGalleryView';
import ShareProperty from './ShareProperty';
import { dateAndTimeFormat, updateCkEditorValues } from '../../../config/configData';

library.add(faCog, faPrint, faGripHorizontal, faList);

// tooltip styles
const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#3c6177',
    color: '#fff',
    boxShadow: theme.shadows[10],
    fontSize: 13,
    fontWeight: '500',
    margin: 0,
    textTransform: 'capitalize',
  },
}))(Tooltip);

const AnotherLightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: config.templateColor,
    color: '#fff',
    boxShadow: theme.shadows[10],
    fontSize: 14,
    fontWeight: '500',
    margin: 0,
  },
}))(Tooltip);

function DataTables (props) {

  const [allUsersData,setAllUsersData] = useState([]);
  const [deleteRowDataId,setDeleteRowDataId] = useState({});
  const [tableFields,setTableFields] = useState(props.getTableFields());
  const [originalTableFields,setOriginalTableFields] = useState(props.getTableFields());
  const [tablefieldsToShow,setTablefieldsToShow] = useState(props.getTableFields());
  const [isOpenShowHideColumnsModal,setIsOpenShowHideColumnsModal] = useState(false);
  const [first,setFirst] = useState(0);
  const [rows,setRows] = useState(20);
  const [sortified,setSortified] = useState(false);
  const [direction,setDirection] = useState('asc');
  const [filterCriteria,setFilterCriteria] = useState({ limit: 20, page: 1, criteria: [], sortfield: 'created', direction: 'desc' });
  const [selectedRows,setSelectedRows] = useState([]);
  const [selectedRowsId,setSelectedRowsId] = useState();
  const [totalRecords,setTotalRecords] = useState([]);
  const [exportData,setExportData] = useState([]);  
  const [isLoading,setIsLoading] = useState(false);
  const [selectedScreenPermission,setSelectedScreenPermission] = useState('');
  const [loginRole,isLoginRole] = useState('Admin');
  const [startDate,setStartDate] = useState();
  const [endDate,setEndDate] = useState();
  const [apiUrl,setApiUrl] = useState(props.apiUrl);
  const [totalRecordsLength,setTotalRecordsLength] = useState(0);
  const [tabType,setTabType] = useState(props.tabType);
  const [mobileListFields,setMobileListFields] = useState([]);
  const [dateFormat,setDateFormat] = useState();
  const [selectschool,setSelectschool] = useState();
  const [roleType,setRoleType] = useState();
  const [openId,setOpenId] = useState(1);
  const [redirecToLogin,setRedirecToLogin] = useState(false);
  const [blockFamily,setBlockFamily] = useState(false);
  const [selectActions,setSelectActions] = useState();
  const [viewType,setViewType] = useState('list');
  const [actions,setActions] = useState();
  const [displayBreadCrumbField,setDisplayBreadCrumbField] = useState();
  const [displayBreadCrumbValue,setDisplayBreadCrumbValue] = useState();
  const [width,setWidth] = useState(window.innerWidth);
  const [height,setHeight] = useState(window.innerHeight);
  const [globalSearch,setGlobalSearch] = useState();
  const [showMultiSelectDropDown,setShowMultiSelectDropDown] = useState(false);
  const [multiSelectTypes,setMultiSelectTypes] = useState();
  const [actionsTypes,setActionTypes] = useState(props.actionsTypes);
  const [userStatus,setUserStatus] = useState();
  const [selectedAutoCompleteValue,setSelectedAutoCompleteValue] = useState();
  const [suggestions,setSuggestions] = useState([]);
  const [confirmModalText,setConfirmModalText] = useState();
  const [confirmType,setConfirmType] = useState();
  const [emailTemplateData,setEmailTemplateData] = useState();
  const [redirectToRoute,setRedirectToRoute] = useState(false);
  const [searchInDateRangeField,setSearchInDateRangeField] = useState(props.searchInDateRangeField ? props.searchInDateRangeField : "created");
  const [fieldMap,setFieldMap] = useState({});
  const [formType,setFormType] = useState();
  const [page,setPage] = useState(1);
  const [sortCount,setSortCount] = useState();
  const [confirmText,setConfirmText] = useState();
  const [leaveStatus,setLeaveStatus] = useState();

  const [isOpenRolesModal,setIsOpenRolesModal] = useState(false);
  const [isOpenConfirmationModal,setOpenConfirmationModal] = useState(false);
  const [isOpenFormModal,setIsOpenFormModal] = useState(false);
  const [openBulkUploadModal,setOpenBulkUploadModal] =  useState(false);
  const [openExportToCsvModal, setOpenExportToCsvModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [sessionExpiryModal,setSessionExpiryModal] = useState(false);
  const [openTemplateModal,setOpenTemplateModal] = useState(false);
  const [isOpenProductsModal,setIsOpenProductsModal] = useState(false);
  const [openDeleteModal,setOpenDeleteModal] = useState(false);
  const [displayViewOfForm,setDisplayViewOfForm] = useState(props.displayViewOfForm);
  const [openUserPasswordResetModal,setOpenUserPasswordResetModal] = useState(false);
  const [isPreviewModal,setIsPreviewModal] = useState(false);
  const [openSendEmailModal,setOpenSendEmailModal] = useState(false);
  const [tableRowData, setTableRowData] = useState({}); 
  const [listPreferences,setListPreferences] = useState("");
  // const [saveCriteria, setSaveCriteria] = useState({ criteria: [] });
  // const [savedFiltCriteria, setSavedFiltCriteria] = useState({}); 
  const [sortField, setSortField] = useState("");
  const [selectedId, setSelectedId] = useState(props.selectedId);
  const [iscriteria, setIsCriteria] = useState(true);
  const [dirty, setDirty] = useState(false);
  const [isEditConfirmationModal, setIsEditConfirmationModal] = useState(false);
  const [filters, setFilters] = useState(null);
  const [filterEvent, setFilterEvent] = useState({});
  const [checkValue, setCheckValue] = useState([]);
  const [properties, setProperties] = useState([]);
  const [saveSearchFilterObject, setSaveSearchFilterObject] = useState({});
  const [sortOrder, setSortOrder] = useState();

  let filterOprRef = useRef(null);
  let formModalRef = useRef(null);
  const csvLinkRef = useRef();
  const formFields = props.formFields() ? props.formFields() : [];
  const [customFormAction, setCustomFormAction] = useState('');

  let user = localStorage.getItem('loginCredentials')? JSON.parse(localStorage.getItem('loginCredentials')) : {};
  let screenPermissions = RolePermissions.screenPermissions(props.displayName);
  let isEditPermission = screenPermissions?.actions?.includes('Update');
  let isDeletePermission = screenPermissions?.actions?.includes('Delete');

  const [openAddNotesModal, setOpenAddNotesModal] = useState(false)
  const [contextTypeAction, setContextTypeAction] = useState()
  const [itemRowData, setItemRowData] = useState()

  useEffect(() => {
    readData();
    getFieldTypes();

    if (props.type === 'Searches') {
      if (Object.keys(props.locationState).length > 0){
        openFormModal(props.locationState?.criteria, 'edit')
      }else{
        openFormModal({}, 'add')
      }
    }

    let employeeId = JSON.parse(localStorage.getItem('loginCredentials'));
    let listPreferences = JSON.parse(localStorage.getItem('listPreferences'));
    if(employeeId && employeeId.listPreferences && employeeId.listPreferences._id){
      setListPreferences(employeeId.listPreferences._id);
      if(listPreferences?.columnOrder && listPreferences?.columnOrder[formatPropType(props.type)]){
        let originalScreenFields = listPreferences?.columnOrder[formatPropType(props.type)];
        setTableFieldsFromListPreferences(originalScreenFields);
      }
      else {
        return fetchMethodRequest('GET', `listPreferences/${employeeId.listPreferences._id}`)
        .then(async (response) => {
          let sessionexpired = await localStorage.getItem('sessionexpired')
          if (sessionexpired == "true") {
            setSessionExpiryModal(true)
          }
          let originalScreenFields = response.details?.columnOrder?.[formatPropType(props.type)];
          setTableFieldsFromListPreferences(originalScreenFields);
        });
      }
    }

    // getTableFieldsOrder();
    updateColumnsOrder(null);
  }, []);

  useEffect(() => {
    if (props.onRef) {
      props.onRef({
        getDataFromServer,
        filterCriteria,
        properties,
        saveSearchFilterObject
      })
    }
  }, [props.onRef, filterCriteria, properties, saveSearchFilterObject]);


  const setTableFieldsFromListPreferences = (originalScreenFields) => {
    let localFields =props.getTableFields();
    let finalFields = originalScreenFields.map((field) => {
      let fieldIndex = localFields.findIndex(f => f.field === field.field);
      if (fieldIndex > -1) {
        localFields[fieldIndex].addedInSettings = true;
        return Object.assign(localFields[fieldIndex],field);
      }
      return { field: 'noField'}
    }).filter((field) => field.field !== 'noField')
    let localMissedFields = localFields.filter( field => !field.addedInSettings);
    finalFields = [...finalFields, ...localMissedFields];
    setTimeout(() => {
      setOriginalTableFields(finalFields);
      setTablefieldsToShow(finalFields);
    }, 100);
  }

  const readData = async () => {
    await getDataFromServer(filterCriteria);
    if (props.addSelectedRecord) {
      openFormModal('', 'add')
    } else if (props.viewSelectedRecord) {
      getActions();
      openFormModal('','view')
    }else if (props.editSelectedRecord){
      openFormModal('','edit')
    }
  }


  const getFieldTypes = () => {
    let tableFields = props.getTableFields();
    let fieldMap = {};
    tableFields.forEach((col) => {
      fieldMap[col.field] = col.fieldType ? col.fieldType.toLowerCase() : col.type=="number"? col.type : null;

    });
    setFieldMap(fieldMap)
  }

  const getActions = async (e) => {
    let selRows = e ? e : [];
    let actionsTypes = props.actionsTypes ? [...props.actionsTypes] : [];
    let result = [];
    if (actionsTypes && actionsTypes.length) {
      for (let i = 0; i < actionsTypes.length; i++) {
        if (actionsTypes[i]['options'] && actionsTypes[i]['options'].length > 0) {
          let options = actionsTypes[i]['options']
          for (let j = 0; j < options.length; j++) {
            if (options[j]['show'] === true) {
              if (selRows && selRows.length >= 2 && options[j]['multiple'] === true) {
                result.push({ "label": options[j]['label'], "value": options[j]['value'], "field": options[j]['field'], "action": actionsTypes[i]['action'] })
              } else if (selRows && selRows.length === 1) {
                result.push({ "label": options[j]['label'], "value": options[j]['value'], "field": options[j]['field'], "action": actionsTypes[i]['action'] })
              }
            }
          }
        }
      }
    }
    setActionTypes(result);
  }

  const handleClickOutside = (event) => {
    if (
      closeMultiSelectDropdown.current &&
      !closeMultiSelectDropdown.current.contains(event.target)
    ) {
      setShowMultiSelectDropDown(false);
    }
  };

  const updateDimensions = async () => {
    setWidth(window.innerWidth);
    setHeight(window.height);
  };

  const setEncryptFields = async () => {
    let encryptFields = [];
    for (let i = 0; i < tablefieldsToShow.length; i++) {
      //condition to match fieldType
      if (tablefieldsToShow[i].fieldType == "encryptedField") {
        encryptFields.push(tablefieldsToShow[i]['field']);
      }
    }
    setEncryptFields(encryptFields)
  }
  // Handle Table fields to show in datatable
  const getTableFieldsOrder =() => {
    setTablefieldsToShow([]);
    setIsLoading(true);

    let columns = originalTableFields;
    let tempTableFields = [];
    let newTableFields = [];
    let staticTableFields = [...originalTableFields];

    if (columns) {
      for (let i = 0; i < columns.length; i++) {
        for (let j = 0; j < staticTableFields.length; j++) {
          if (columns[i].field === staticTableFields[j].field && viewType === 'list') {
            let pushItem = staticTableFields[j];
            pushItem.show = columns[i].show;
            pushItem.displayInSettings = columns[i].displayInSettings;
            tempTableFields.push(pushItem);
            break;
          }
          if (columns[i].field === staticTableFields[j].field && viewType === 'grid') {
            let pushItem = staticTableFields[j];
            pushItem.displayInSettings = columns[i].displayInSettings;
            pushItem.mobile = columns[i].mobile;
            tempTableFields.push(pushItem);
            break;
          }
        }
      }
    } else {
      tempTableFields = originalTableFields
    }

    newTableFields = tempTableFields;

    let TableFieldsToShow = [];
    if (newTableFields && newTableFields.length > 0) {
      newTableFields.map(item => {
        if (item.show && viewType === 'list') {
          TableFieldsToShow.push(item)
        }
        if (item.mobile && viewType === 'grid') {
          TableFieldsToShow.push(item)
        }
      })
    }

    setTablefieldsToShow(TableFieldsToShow);
    setIsLoading(false);
  }
  const changeCheckIcon = async (index, subIndex, key, value) => {
    let tablefieldsToShow = tablefieldsToShow;
    if (tablefieldsToShow[index]['options'][subIndex]['checkIcon'] === true) {
      let filterCriteria = Object.assign({}, filterCriteria);
      if (filterCriteria && filterCriteria['criteria'] && filterCriteria['criteria'].length > 0) {
        let obj = filterCriteria.criteria.find(x => x.key === key);
        let objIndex = filterCriteria.criteria.indexOf(obj)
        let objValue = obj.value.find(y => y === value)
        let i = obj.value.indexOf(objValue);
        filterCriteria.criteria[objIndex]['value'].splice(i, 1);
        let length = filterCriteria.criteria[objIndex]['value'] ? filterCriteria.criteria[objIndex]['value'].length : 0;
        if (length === 0) {
          filterCriteria.criteria.splice(objIndex, 1)
        }
      }
      tablefieldsToShow[index]['options'][subIndex]['checkIcon'] = !tablefieldsToShow[index]['options'][subIndex]['checkIcon'];
      setTablefieldsToShow(tablefieldsToShow);
      setFilterCriteria(filterCriteria);
      await onMultiSelectFilterChange(key, value, 'pop', index)
    } else {
      let filterCriteria = Object.assign({}, filterCriteria);
      tablefieldsToShow[index]['options'][subIndex]['checkIcon'] = !tablefieldsToShow[index]['options'][subIndex]['checkIcon'];
      setTablefieldsToShow(tablefieldsToShow);
      if (filterCriteria && filterCriteria['criteria'] && filterCriteria['criteria'].length > 0) {
        let obj = filterCriteria.criteria.find(x => x.key === key);
        let objIndex = filterCriteria.criteria.indexOf(obj);
        await onMultiSelectFilterChange(key, value, 'push', objIndex)
      } else {
        await onMultiSelectFilterChange(key, value, 'push', index)
      }
    }
  }
  const changeViewType = async (viewType) => {
    setViewType(viewType);
    await getTableFieldsOrder()
  }
  // Handle Table fields order of display in DataTable
 const updateColumnsOrder = (currentOrder)  => {
    setIsLoading(true);
    let orgTableFields = [...originalTableFields];
    let order = currentOrder ? currentOrder : null;
    let updatedOrder = [];
    let unmatchedTableFields = [...orgTableFields];

    if (order && order.length > 0) {
      order.map(async item => {
        if (item && item.props && item.props.field) {
          orgTableFields.map(col => {
            if (item.props.field === col.field) {
              updatedOrder.push(
                {
                  field: col.field,
                  show: col.show,
                  header: col.header,
                  displayInSettings: col.displayInSettings,
                  mobile: col.mobile,
                  label:col.label?col.label:col.header
                }
              )
            }
          })
        }
      })

      order.map(async item => {
        if (item && item.props && item.props.field) {
          for (let i = 0; i < unmatchedTableFields.length; i++) {
            if (item.props.field === unmatchedTableFields[i].field) {
              unmatchedTableFields.splice(i, 1)
            }
          }
        }
      })

      if (unmatchedTableFields && unmatchedTableFields.length > 0) {
        unmatchedTableFields.map(col => {
          updatedOrder.push(
            {
              field: col.field,
              show: false,
              header: col.header,
              displayInSettings: col.displayInSettings,
              mobile: false,
              label:col.label?col.label:col.header
            }
          )
        })
      }
    } else {
      orgTableFields.map(async col => {
        updatedOrder.push(
          {
            field: col.field,
            show: col.show,
            header: col.header,
            displayInSettings: col.displayInSettings,
            mobile: col.mobile,
            label: col.label?col.label:col.header
          }
        )
      });
    }

    /**@Fetch the listPreferences from loginCredentials */
    getTableFieldsOrder(orgTableFields);
  }

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let dateFormat = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.dateFormat ? storeData.settingsData.settings.dateFormat : "DD-MM-YYYY"
    if (state.dateFormat != dateFormat) {
      return { dateFormat: dateFormat };
    }
    return { dateFormat: dateFormat };

  }

  const getLoginRole = () => {
    let loginData = localStorage.getItem('loginCredentials');
    if (loginData) {
      loginData = JSON.parse(loginData);
      if (loginData && loginData.role) {
        setLoginRole(loginData.role);
      }
    }
  }

  const updateSlNoToData = (itemdata, text) => {
    let modifiedData = [];
    let txt=text+1;
    
    itemdata.forEach((item, index) => {
      if (props.flags) {
        let flags = props.flags
        if (item[flags['label']] == flags['value']) {
          modifiedData.push(item);
        }
      } 
      
      else {
        item.Sno=txt;
        txt++;

        modifiedData.push(item);
      }
    });
    return modifiedData;
  }

  const updateDateFormat = (itemdata, dateFormat) => {
    let modifiedData = [];
    for (let i = 0; i < itemdata.length; i++) {
      for (let k = 0; k < tablefieldsToShow.length; k++) {
        if ("Date" == tablefieldsToShow[k]['fieldType']) {
          itemdata[i][tablefieldsToShow[k]['field']] = tablefieldsToShow[k]['fieldUse'] != "dateAndTime" ?
            dateFormats.formatDate(
              itemdata[i][tablefieldsToShow[k]['field']],
              tablefieldsToShow[k]['dateFormat'] ? tablefieldsToShow[k]['dateFormat'] : dateFormat)
            : itemdata[i][tablefieldsToShow[k]['field']];
        }
      }
      modifiedData.push(itemdata[i])
    }
    return modifiedData;
  }

  //get table list data from server with filters if any
  const getDataFromServer = async(filterCriteria, type, options) => {
    let url = getAPIUrl();
    let dateFormat = props.dateFormat
    if (url) {
      let apiUrl, body;
      let Method = 'GET'
      setIsLoading(true);
      setSelectedRows('');
      if (type == 'refresh') {
        let fieldsToShow = tablefieldsToShow;
        if (fieldsToShow && fieldsToShow.length > 0) {
          for (let i = 0; i < fieldsToShow.length; i++) {
            let options = fieldsToShow[i].options;
            if (options && options.length) {
              for (let j = 0; j < options.length; j++) {
                options[j]['checkIcon'] = false
              }
            }
          }
        }
        setAllUsersData([]);
        setRoleType('');
        setTablefieldsToShow(fieldsToShow);
      }
      if (!filterCriteria || !filterCriteria['criteria']) {
        let filterCriteria = {};
        filterCriteria = { limit: 20, page: 1, criteria: [], direction: 'desc', sortfield: 'created' };
      }
      if (type === 'clear-all-filters' && filterCriteria) {
        delete filterCriteria.globalSearch;
        setGlobalSearch('')
        filterCriteria = { limit: 20, page: 1, criteria: [], direction: 'desc', sortfield: 'created' };
        setFilterCriteria(filterCriteria);
        setFirst(0);
        setRows(20);
      }
      if (props.params) {
        let params = props.params
        for (let i in params) {
          if (i) {
            let obj = {}
            obj['key'] = i
            obj['value'] = params[i]
            obj['type'] = 'regexOr'
            filterCriteria['criteria'].push(obj)          }
        }
      }
      //When a screen has a default filter to be applied
      if (props.defaultCriteria && props.defaultCriteria.length > 0) {
        if (filterCriteria && filterCriteria['criteria'] && filterCriteria['criteria'].length > 0) {
          let newFilt = filterCriteria['criteria'];
          let dc = Array.from(props.defaultCriteria);
          for (let i = 0; i < newFilt.length; i++) {
            for (let j = 0; j < dc.length; j++) {
              if (newFilt[i].key == dc[j].key) {
                dc.splice(j, 1)
              }
            }
          }
          filterCriteria['criteria'] = filterCriteria['criteria'].concat(dc);
        }
        else {
          filterCriteria['criteria'] = props.defaultCriteria
        }
      }
      if (props.customType === 'myproperties' && filterCriteria !== undefined && type !== 'sorting') {
        filterCriteria['sortfield'] = "updated"
      }
      //applyng filter when a row item is deleted 
      if (filterCriteria == undefined) {
        filterCriteria = { limit: 20, page: 1, criteria: [], direction: 'desc', sortfield: 'created' };
        apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
      }
      if (props.filterExtension) {
        apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}&&${props.filterExtension}`
      }
      else if (props.hasQueryInCall) {
        apiUrl = `${url}&filter=${JSON.stringify(filterCriteria)}`;
      }
      else if (type == 'clearFilter' && (Object.keys(filterCriteria).length > 0)) {
        const relateAutoCompleteFields = tablefieldsToShow.filter(field => field.type === "relateAutoComplete").map(field => field.fieldName);
        if (relateAutoCompleteFields.includes(options.field)) {
          options.field = options.field + 'Search';
        }
        filterCriteria.criteria = filterCriteria.criteria.filter(criterion => criterion.key !== options.field);
        apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
      }
      else {
        apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
      }

      let limit=filterCriteria.limit;
      let page=filterCriteria.page;

      if (props.type === 'Searches') {
        if (type == 'searchFilters' || (Object.keys(filterCriteria.criteria).length > 0)) {
          setFilterCriteria(filterCriteria);
          apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}&isFilterApplied=${true}`;
        } else if (props.locationState && Object.keys(props.locationState).length > 0) {
          apiUrl = `savedSearches/getProperties?filter=${JSON.stringify(filterCriteria)}&id=${props.locationState._id}`;
        } else {
          setTotalRecordsLength(0);
          setFilterCriteria({});
          setIsLoading(false);
          setAllUsersData([]);
          return [];
        }
      }
      if (props.type === 'ShowMatchedProperties') {
        if (props.locationState && props.isFromDashboard) {
          apiUrl = `recentMatchedProperties/getRecentPropertiesBasedOnId/${props.locationState}?filter=${JSON.stringify(filterCriteria)}`;
        } else if (props.locationState){
          apiUrl = `${url}/getPropertiesBasedOnId/${props.locationState}?filter=${JSON.stringify(filterCriteria)}`;
        } else {
          apiUrl = `savedSearches/getProperties?filter=${JSON.stringify(filterCriteria)}&agentEmail=${user.email}`;
        }
      }

      return fetchMethodRequest(Method, apiUrl, body)
        .then(async (response) => {
          let apiResponseKey = props.apiResponseKey;
          let sessionexpired = await localStorage.getItem('sessionexpired')
          if (sessionexpired == "true") {
            setSessionExpiryModal(true);
          }
          let responseData = [];
          let recordsLength = 0;
          if (response && response.details && response.details.length > 0) {
            responseData = response.details;
            recordsLength = response.details.length;
          } else {
            if (response && response[apiResponseKey] && response[apiResponseKey].length && response[apiResponseKey].length >= 0) {
              if (response.pagination && response.pagination.totalCount) {
                recordsLength = response.pagination.totalCount;
              }
              if (props.type === 'Searches') {
                setProperties(response['totalMyproperties']);
                setSaveSearchFilterObject(response.query);
              }
              responseData = updateSlNoToData(response[apiResponseKey], limit*(page-1));
              responseData = updateDateFormat(responseData, dateFormat);
            } else {
              if (response.pagination && (response.pagination.totalCount || response.pagination.totalCount == 0)) {
                recordsLength = response.pagination.totalCount;
              }
            }
          }
      
          setAllUsersData(responseData);
          setIsLoading(false);
          // if(filterCriteria.page === 1){
          setTotalRecordsLength(recordsLength);
          // }
          return responseData;
        }).catch((err) => {
          return err;
        });
    }
  }


  const getAPIUrl = () => {
    return props.apiUrl;
  }
  const getFilterUrl = (filterCriteria, type) => {
    return props.apiUrl;
  }

  const checkedFields = (data) => {
    getDataToExport(data)
  }

  //Get all data of current screen with filters applied from server to export to CSV
  const getDataToExport = async (data) => {
    setIsLoading(true);
    let filter = {...filterCriteria};
    delete filter['limit'];
    delete filter['page'];
    let url = getAPIUrl();
    let apiUrl;
    apiUrl = `${url}?filter=${JSON.stringify(filter)}&type=exportToCsv`;
    return fetchMethodRequest('GET', apiUrl)
      .then(async (response) => {
        let sessionexpired = await localStorage.getItem('sessionexpired')
        if (sessionexpired == "true") {
          setSessionExpiryModal(true)
        }
        if (response && response[url]) {
          let exportData = response[url];
          exportData.forEach((item) => {
            for (let key in item) {
              let formFields = props.getTableFields();
              /**@Find the Corresponding @FormField based on @key in each iteration */
              let findFormFieldBasedOnEachKey = formFields.find(formField =>  formField.name === key)
              if (findFormFieldBasedOnEachKey) {

                /**@HandlingMultipleAutoRelateFields */
                if(findFormFieldBasedOnEachKey.type == "relateAutoComplete" && findFormFieldBasedOnEachKey.isMultiple){
                  if(item[key].length > 0){
                    item[key] = item[key].map( arrItem => arrItem[findFormFieldBasedOnEachKey.searchField]).toString();
                  }
                }
                /**@HandlingAutoRelateFields */
                if (findFormFieldBasedOnEachKey.type === "relateAutoComplete" && !findFormFieldBasedOnEachKey?.isMultiple) {
                  item[key] = item?.[key]?.[findFormFieldBasedOnEachKey.searchField]

                    /**@Deletx`e the Key we are using @Search Ex {Project+Search} */
                    delete item[key+"Search"];
                }
                /**@HandlingDates */
                if(findFormFieldBasedOnEachKey.type === "date"){
                  let date = moment(item[key]).format(findFormFieldBasedOnEachKey?.dateFormat)
                  item[key] = date;
                }
              }

              /**@DeletingUnWantedInformation Like @_id @__V @createdBy @active {MONGO FIELDS USED IN BACKEND} */
              delete item._id;
              delete item.__v;
              delete item.createdBy;
              delete item.active;
              delete item.photo
              delete item.isTwoFactorAuthentication
              delete item.isRemember
              delete item.enableTwoFactAuth
              delete item.entityType
              delete item.listPreferences

              for (const key in data) {
                if (data[key] === false) {
                  delete item[key];
                }
              }
            }
          });
          setExportData(exportData);
          setIsLoading(false);
          csvLinkRef.current.link.click();
          closeExportToCsvModal();
        }
      }).catch((err) => {
        setIsLoading(false);
        return err;
      });
  }

  //open Form modal
  const openFormModal = async (rowData, type, dirty, customAction) => {
    if(customAction){
      setCustomFormAction(customAction);
    } else {
      setCustomFormAction('');
    }
    setFormType(type ? type : 'add');
    setIsOpenFormModal(true);
    setSelectedRows('');
    setSelectedId(rowData._id);
    if (dirty) {
      setTableRowData(rowData);
      setIsEditConfirmationModal(true);
    } else if (type == 'add') {
      setIsEditConfirmationModal(false)
      if (isOpenFormModal && formModalRef) {
        setIsOpenFormModal(false);   //closing the form before opening to reset old values
        await formModalRef.getFormFields(type);
        setIsOpenFormModal(true);
      }
    } else if (type === 'view') {
      setIsEditConfirmationModal(false)
      if (isOpenFormModal && formModalRef) {
        await formModalRef.handleViewDisplay(rowData, type);
      }
    } else if (type == 'edit') {
      setIsEditConfirmationModal(false)
      setTableRowData(rowData);
      if (isOpenFormModal && formModalRef) {
        await formModalRef.getRowData(rowData, 'edit');
      }
    } else if (type === 'notes') {
      setTableRowData(rowData);
      if (isOpenFormModal && formModalRef) {
        await formModalRef.addNotes(rowData, 'notes');
      }
    }
  }

  //close form modal
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
    setRedirectToRoute(true);
    setDirty(false);
  }

  const submitActionsData = async (method, url, body) => {
    return fetchMethodRequest(method, url, body)
      .then(async (response) => {
        let sessionexpired = localStorage.getItem('sessionexpired')
        if (sessionexpired == "true") {
          setSessionExpiryModal(true);
        }
        setOpenDeleteModal(false);
        setActions('');
        setSelectedRows('');
        setSelectedRowsId('');
        if (response && response.respCode) {
          showToasterMessage(response.respMessage, 'success');
          getDataFromServer(filterCriteria);
        } else if (response && response.errorMessage) {
          showToasterMessage(response.errorMessage, 'error');
        }
      }).catch((err) => {
        return err;
      });
  }
  const handleActions = async (apiType) => {
    let ids = selectedRows.map(id=>id._id) 
    let method, url, body = {}
    if (confirmType === 'Delete' || confirmType === 'Block' || confirmType === 'UnBlock') {
      url = `${apiUrl}/${apiType}`;
      method = 'POST';
      body = {
        selectedIds: ids 
      }
    }
    submitActionsData(method, url, body)
  }
  // delete selected row
  const deleteSelectedRow = async () => {
    if (confirmType === 'Delete') {
      handleActions('multiDelete')
    } else if (confirmType === 'Block') {
      handleActions('multiblock?block=true')
    } else if (confirmType === 'UnBlock') {
      handleActions('multiblock?unblock=true')
    } else if (confirmType === 'ResetPassword') {
      setOpenUserPasswordResetModal(true);
      setOpenDeleteModal(false);
      setSelectedRows(selectedRows);
    }
  }

  //close delete modal
  const closeDeleteModal = async () => {
    setOpenDeleteModal(false);
    setActions('');
  }
  const setDeleteRecords = async (rowData, selectActions) => {
    let selectedRowsId = [];
    selectedRowsId.push(rowData)
    setSelectedRows(selectedRowsId);
    setConfirmType("Delete");
    deleteConfirmAction(rowData, selectActions);
    setIsOpenFormModal(false);
  }

  // conformation for delete item
  const deleteConfirmAction = async (rowData, selectActions) => {
    let selectedRowsId = [];
    if (selectedRows && selectedRows.length && selectedRows.length > 0) {
      selectedRows.forEach((item, index) => {
        selectedRowsId.push(item._id)
      })
      setSelectedRowsId(selectedRowsId);
      setOpenDeleteModal(true);
      setSelectActions(selectActions);
    }
    if (rowData) {
      setDeleteRowDataId(rowData);
      setOpenDeleteModal(true);
      setSelectActions(selectActions);
    }
  }

  //change dropdown elememt
  const changeDropDownElement = (event) => {
    dt.filter(event.target.value, event.target.name, 'equals');
  }


  const getTableFieldItem = (field) => {
    for (let i = 0; i < tablefieldsToShow.length; i++) {
      if (tablefieldsToShow[i].field == field) {
        return tablefieldsToShow[i];
      }
      //condition to match fieldType
      if (tablefieldsToShow[i].fieldType == field) {
        return tablefieldsToShow[i]['field'];
      }
    }
    return null;
  }

  const getColorFromOptions = (options, name) => {
    if (options) {
      for (let i = 0; i < options.length; i++) {
        if ((options[i].value == name) && options[i].color) {
          return options[i].color;
        }
      }
    }
    return 'success';
  }
  const getUserData = (_id, type) => {
    let data = allUsersData;
    for (let i = 0; i < data.length; i++) {
      if (data[i]['_id'] === _id) {
        return i
      }
    }
  }
  
  const getIconValue = (rowData, labelKey) => {
    if (labelKey && labelKey.options && labelKey.options.length > 0) {
      for (let i = 0; i < labelKey.options.length; i++) {
        if (labelKey.options[i].value === rowData[labelKey.field]) {
          return labelKey.options[i].displayText;
        }
      }
    }
    return '';
  }
  
  const getAUserData = (_id, type) => {
    let data = allUsersData;
    for (let i = 0; i < data.length; i++) {
      if (data[i]['_id'] === _id) {
        return data[i]
      }
    }
  }

  const onOpenViewModal = async (rowData, type,from) => {
    let rowDataIndex = getUserData(rowData['_id'], '_id');
    if(from == "route") { rowData = getAUserData(rowData['_id'], '_id')}
    let _id = rowData['_id'];
    let status = rowData['status'] ? rowData['status'] : ''
    let keys = Object.keys(rowData);

    let formFields = [];
    if (formFields) {
      if (props.type) {
        if (rowData) {
          let values, fieldType, searchField, self = this, icon = false;

          // hari get all the labels from 
          keys.forEach(async function (key) {
            let labelKey = await self.getTableFieldItem(key);
            if (labelKey == null) {
              labelKey = key;
            } else {
              let val = rowData[key];
              if (labelKey.fieldType === 'icon') {
                val = self.getIconValue(rowData, labelKey);
                icon = true;
              }
              fieldType = labelKey.fieldType ? labelKey.fieldType : null
              searchField = labelKey.searchField ? labelKey.searchField : null
              let options = labelKey.options ? labelKey.options : []
              labelKey = labelKey.header
              if (val) {
                if (fieldType && searchField && fieldType == "relateAutoComplete") {
                  values = {
                    label: labelKey,
                    value: icon ? val : rowData[key][searchField],
                    fieldType: fieldType
                  }
                } else {
                  values = {
                    label: labelKey,
                    value: icon ? val : rowData[key],
                    fieldType: fieldType,
                    options: options
                  }
                }
                formFields.push(values);
                icon = false;
              }
            }
            // if (key == 'fromTime' || key == 'toTime') {
            //   let date = dateFormats.addDaysToDate(rowData[key], 1);
            //   date = dateFormats.formatDate(date, config.timeFormat);
            //   values = {
            //     label: key,
            //     value: date
            //   }
            //   formFields.push(values);
            // }
          });
        }
      }
      if (displayViewOfForm === 'modal') {
        setIsOpenFormModal(true);
        setUserStatus(status);
        setFormType(type);
        await formModalRef.getViewData(formFields, 'view', rowDataIndex, rowData, _id);
      } else if (displayViewOfForm === 'screen') {
        let displayBreadCrumbField = getTableFieldItem('Link');
        setIsOpenFormModal(true);
        setUserStatus(status);
        setFormType(type);
        setDisplayBreadCrumbField(displayBreadCrumbField);
        setDisplayBreadCrumbValue(rowData[displayBreadCrumbField])
      }
    }
    await getDataFromServer();

  }

  const closeViewModal = async () => {
    
    setOpenViewModal(false);
  }
  //on changing pagination
  const onPageChange = async (event) => {
    let filter = {...filterCriteria};
    if (event && event.rows) {
      let currentPage = event.page + 1;
      filter['limit'] = event.rows;
      filter['page'] = currentPage;

      setRows(event.rows);
      setFirst(event.first);
      setPage(event.page + 1);
    }
    setFilterCriteria(filter)
    getDataFromServer(filter)
  }

 const onPageChangeAccordion = async (event) => {
    let filterCriteria = filterCriteria;
    let id = openId;
    if (event && event.rows) {
      let currentPage = event.page + 1;
      filterCriteria['limit'] = event.rows;
      filterCriteria['page'] = currentPage;
    }
  }
  //sorting fields
  const sortChange = (event) => {
    const relateAutoCompleteFields = tablefieldsToShow.filter(field => field.type === "relateAutoComplete").map(field => field.fieldName);
    setSelectedRows('');
    let sort = sortCount;
    let filters = { ...filterCriteria }
    if (event && event['sortField']) {
      sort = sort == 0 ? sort + 1 : 0;
      let sortField = event['sortField'];
      if (relateAutoCompleteFields.includes(sortField)) {
        sortField = sortField + 'Search';
      }
      filters['direction'] = sort == 0 ? "desc" : 'asc',
      filters['sortfield'] = sortField,
        setSortField(sortField);
      setSortOrder(event.sortOrder);
      setIsLoading(true);
      setSortCount(sort);
      setFilterCriteria(filters);
      getDataFromServer(filters, 'sorting');
    }
  }

  const confirmActionType = async (type) => {
    if (type === 'Delete') {
      setConfirmType(type);
      setConfirmModalText('Are you sure want to Delete')
      deleteConfirmAction()
    } else if (type === 'Block') {
      setConfirmType(type);
      setConfirmModalText('Are you sure want to Block')
      deleteConfirmAction()
    } else if (type === 'ResetPassword') {
      setConfirmType(type);
      setConfirmModalText('Are you sure want to Block');
      setOpenUserPasswordResetModal(true);
      setOpenDeleteModal(false);
      setSelectedRows(selectedRows);
    } else if (type === 'Send Email') {
      setOpenSendEmailModal(true);
    }
  }

  //onActionsChange
  const onActionsChange = async (event, type) => {
    if (type == 'dropdownFilter') {
      for (let i in actionsTypes) {
        if (actionsTypes[i].value === event.target.value) {
          if (actionsTypes[i].action) {

            let filter = { ...filterCriteria };
            filter['limit'] = rows;
            filter['page'] = page;

            actionsTypes[i].action(selectedRows, actionsTypes[i].field, actionsTypes[i].value)
            getDataFromServer(filter);
            return;
          }
        }
      }
      confirmActionType(event.target.value)
    }
  }

  const rowFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={representatives}
        itemTemplate={representativesItemTemplate}
        onChange={(e) => options.filterApplyCallback(e.value)}
        optionLabel="name"
        placeholder="Any"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: '14rem' }}
      />
    );
  };

 const selectFilterTemplate = (options) => {
    return (
      <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={statusItemTemplate} placeholder="Select One" className="p-column-filter" showClear style={{ minWidth: '12rem' }} />
    );
  };

  const onMultiSelectFilterChange = async (key, value, type, index) => {
    let filterCriteria = filterCriteria;
    if (filterCriteria && type === 'push') {
      let v = [], length = '';
      if (filterCriteria['criteria'] && filterCriteria['criteria'][index] && filterCriteria['criteria'][index]["value"]) {
        v = [...filterCriteria['criteria'][index]["value"]];
        length = v.length;
        v[length] = value;
        filterCriteria['criteria'][index]["value"] = v;
      } else {
        v[0] = value
        filterCriteria['criteria'].push({
          key: key,
          value: v,
          type: 'in'
        });
      }
      setFilterCriteria(filterCriteria)
      await getDataFromServer(filterCriteria)
    } else {
      getDataFromServer(filterCriteria)
    }
  }

  const getCriteriaOfSavedFilt = (jsonq) => {
    let first = 0;
    if (jsonq.page !== 1) {
      first = jsonq.limit * (jsonq.page - 1)
    }
    setPage(jsonq.page);
    setFirst(first);
    setRows(jsonq.limit);
    setFilterCriteria(jsonq);
    // setSavedFiltCriteria(jsonq);
  }

  const onFilterColumns = (event) => {
    setFilterEvent(event.filters);
    
    //The below code is for manually clearing the value in the filter text box & click on apply button clearing filters
    const updatedCriteria = filterCriteria.criteria.filter(filter => {
      let filterKey = filter.key;
      if (filterKey.includes("Search")) {
        filterKey = filterKey.replace("Search", "");
      }
      const eventFilter = event.filters[filterKey];
      if (eventFilter) {
        const constraintValue = eventFilter.constraints[0].value;
        if (constraintValue === null || constraintValue === '') {
          return false;
        }
      }
      return true;
    });

    let res = FunnelFilter(event, fieldMap, sortCount, sortField, props.type, rows, page, updatedCriteria, filterCriteria.globalSearch);
   setFilterCriteria(res);
   setFilters(event.filters);
   getDataFromServer(res);

   if (res.criteria.length > 0) { 
     setIsCriteria(false)
   }
  }

  const getOneDayQuery = ( date ) => {
    let todayDate = moment(new Date(date)).format('YYYY-MM-DD');
    return { $lte: new Date(todayDate + 'T23:59:59Z'), $gte: new Date(todayDate + 'T00:00:00Z') };
  }
  const getPreviousDate = (inputDate) => {
    if (inputDate) {
      const date = new Date(inputDate);
      date.setDate(date.getDate() - 1);
  
      // Format the date in 'YYYY-MM-DD' format
      const formattedDate = date.toISOString().split('T')[0];
  
      return formattedDate;
    } else {
      return null; // or handle the case where date is not provided
    }
  };
  const getNextDate = (inputDate) => {
    if (inputDate) {
      const date = new Date(inputDate);
      date.setDate(date.getDate() + 1);
  
      // Format the date in 'YYYY-MM-DD' format
      const formattedDate = date.toISOString().split('T')[0];
  
      return formattedDate;
    } else {
      return null; // or handle the case where date is not provided
    }
  };

 const formatDateFilters = (filterObj,field) => {
    if(filterObj.value && filterObj.value.length === 2){
      if(filterObj.value[1] !== null){
        const start =dateFormats.formatDate(filterObj.value[0], config.dayYearDateFormat);
        const initialDate = getPreviousDate(start);
        const inputDateForNext = dateFormats.formatDate(filterObj.value[1], config.dayYearDateFormat);
        const nextDate = getNextDate(inputDateForNext);
        return[
          {key: field, value: initialDate , type: 'gte'},
          {key: field, value: nextDate, type: 'lt'}
        ];        
      } else {       
        const inputDate =dateFormats.formatDate(filterObj.value[0], config.dayYearDateFormat);
        const previousDate = getPreviousDate(inputDate);
        let date = getOneDayQuery(previousDate);
        return [{key: field, value: previousDate , type: 'gte'},{key: field, value: inputDate, type: 'lte'}]
        
      }
    }else{
      return [];
    }
  }

  
 

  // on search get data from server
  const onFilterChange = async (event, type) => {
    setIsLoading(true);
    setSelectedRows('');
    if (type === 'dropdownFilter' || type === 'date') {
    }
    let fieldName = '', filter = {...filterCriteria},
      selectedFilterValue, selectedFilter, selecterFilterType, formattedTime, formattedDate, isDateFilter = false;
    if (event) {
      if (event.filters && !type) {
        if (Object.keys(event.filters) && Object.keys(event.filters)[0]) {
          fieldName = Object.keys(event.filters)[0];
        }
        let field = event.filters;
        selectedFilter = field[fieldName];
        if (fieldName == 'date' || fieldName == 'created' || fieldName == 'updated') {
          isDateFilter = true;
          selectedFilterValue = selectedFilter && selectedFilter.value && selectedFilter.value.length == 10 ?
            selectedFilter.value : null;
          let date = dateFormats.addDaysToDate(selectedFilter.value, 1);
          formattedDate = dateFormats.formatDate(date, config.dayYearDateFormat);
          selecterFilterType = 'eq'
        } else {
          selecterFilterType = 'regexOr'
          selectedFilterValue = selectedFilter && selectedFilter.value ? selectedFilter.value : null;
        }
      } else {
        fieldName = event.target.name;
        selectedFilterValue = event && event.target && event.target.value ? event.target.value : null;
        setGlobalSearch(selectedFilterValue);
      }
      if (type == 'dropdownFilter') {
        selecterFilterType = 'in'
      }
      if ((type == 'dropdownFilter' && selectedFilterValue && selectedFilterValue.length && selectedFilterValue.length > 0) || (type !== 'date' && selectedFilterValue && selectedFilterValue.length && selectedFilterValue.length >= 3)) {
        if (fieldName == 'createdBy') {
          fieldName = 'createdBy.name';
        }
        if (fieldName == 'phone') {
          fieldName = 'phone';
        }
        if (fieldName == 'globalSearch') {
          filter.globalSearch = {
            value: selectedFilterValue,
            type: 'user'
          }
          if (event.target.value.length == 0) {
            delete filter.globalSearch;
          }
        } else {
          if (selecterFilterType == 'gte') {
            let obj = filter.criteria.find(x => x.key == fieldName);
            let index = filter.criteria.indexOf(obj);
            if (index != -1) {
              filter['criteria'].splice(index, 1, {
                key: fieldName,
                value: formattedDate,
                type: 'eq'
              });
            } else {
              filter['criteria'].push({
                key: fieldName,
                value: formattedDate,
                type: 'eq'
              });
            }
          } else {
            if (fieldName == 'updated') {
              fieldName = 'updated.name'
            }
            if (filter['criteria'].length == 0 && selecterFilterType != 'lte') {
              filter['criteria'].push({
                key: fieldName,
                value: selectedFilterValue,
                type: selecterFilterType
              });
            } else {
              let obj = filter.criteria.find(x => x.key == fieldName);
              let index = filter.criteria.indexOf(obj);
              if (selecterFilterType == 'lte') {
                if (selectedFilterValue.length == 10) {
                  filter['criteria'].splice(0, 1, ({
                    key: fieldName,
                    value: selectedFilterValue.substring(6, 10) + '-' + selectedFilterValue.substring(3, 5) + '-' + selectedFilterValue.substring(0, 2) + 'T23:59:59Z',
                    type: selecterFilterType
                  }));
                }
              }
              if (index != -1 && selecterFilterType != 'lte') {
                filter['criteria'].splice(index, 1, {
                  key: fieldName,
                  value: selectedFilterValue,
                  type: selecterFilterType
                });
              } else if (selecterFilterType != 'lte') {
                filter['criteria'].push({
                  key: fieldName,
                  value: selectedFilterValue,
                  type: selecterFilterType
                });
              }
            }
          }
        }

        setFilterCriteria(filter);
        await getDataFromServer(filter)
      } else if (type === 'date' && selectedFilterValue && selectedFilterValue.length && selectedFilterValue.length > 0) {
        if (selectedFilterValue.length == 2) {
          let fieldIndex1 =  filter.criteria.findIndex((obj)=> obj.key==fieldName)
          let fieldIndex2 =  filter.criteria.findIndex((obj)=> obj.key==fieldName)
         if (fieldIndex1>=0)filter.criteria.splice(fieldIndex1, 1)
         if (fieldIndex2>=0)filter.criteria.splice(fieldIndex2, 1)
          let startDate = (moment(selectedFilterValue[0]).format('YYYY-MM-DD'));
          let eDate = selectedFilterValue[1] ? JSON.parse(JSON.stringify(selectedFilterValue[1])) : JSON.parse(JSON.stringify(selectedFilterValue[0]))
          eDate = new Date(eDate)
          let endDate = eDate.setDate(eDate.getDate() + 1);
          endDate = (moment(endDate).format('YYYY-MM-DD'));
          filter['limit'] = 20;
          filter['page'] = 1;
          filter.criteria.push({ 'key': fieldName, 'value': startDate, 'type': 'gte' })
          filter.criteria.push({ 'key': fieldName, 'value': endDate, 'type': 'lte' })
        }
        setFilterCriteria(filter);
        await getDataFromServer(filter)
      } else {
        if ((selectedFilterValue == null || (type === 'dropdownFilter' && selectedFilterValue.length == 0) )  && !isDateFilter) {
          // let obj = filter.criteria.find(x => x.key == fieldName);
          // let index = filter.criteria.indexOf(obj);
          // filter.criteria.splice(index, 1);
          if (fieldName == 'globalSearch') {
            filter.globalSearch = {}
            delete filter.globalSearch
          }
          setFilterCriteria(filter);
          await getDataFromServer(filter)
        }
      }
      setIsLoading(false);
    }
  }

  //open Bulk Modal
  const bulkUploadMoadal = () => {
    setOpenBulkUploadModal(true);
  }

  //close Bulk Modal
 const closeBulkModal = async () => {
    setOpenBulkUploadModal(false);
  }

  //open export to csv Modal
  const exportToCsvModal = () => { 
    setOpenExportToCsvModal(true);
  }

  //close export to csv Modal
  const closeExportToCsvModal = () => { 
    setOpenExportToCsvModal(false);
  }

  //select multiple rows to delete
  const onSelectRowsUpdate = async (event) => {
    //If we open form unable to select all records
    // if (isOpenFormModal && event.value.length > 0) {
    //   setSelectedRows([event.value[event.value.length - 1]])
    // }
      setSelectedRows(event.value);
      setCheckValue(event.value.map(e => e._id))
      await getActions(event.value);
  }

  //openConfirmationModal
 const openConfirmationModal = async (rowData, type) => {
    setIsOpenFormModal(false);
    setOpenConfirmationModal(true);
    setTableRowData(rowData);
    setConfirmText(type);
  }

  const closeConfirmationModal = () => {
    setOpenConfirmationModal(false);
  }
  const setFilterCriteriaForActivities = async (editRowDataID) => {
    let filterCriteria = filterCriteria;
    filterCriteria['criteria'].push(
      { key: 'contextId', value: editRowDataID, type: 'eq' }
    )
    setFilterCriteria(filterCriteria);
  }
  const getTooltipFromOtptions = (options, name) => {
    if (options) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].value === name) {
          return options[i].tooltip;
        }
      }
    }
    return '';
  }

  const getArrayBadges = (badge_values)=> {
    let str = [...badge_values].join(',');
    return str;
}

const textEllipses = (item, column, data) => {
  return (
    <div className='textElipses'>
      <span onClick={() => openFormModal(item, 'view')} title={item[column.field]}>
        {data}
      </span >
    </div >
  )
}

  //change table body values//
  // hari need to move to derived class or controller
  const changeFieldValues = (item, column) => {
    let self = this, tableItem;
    tableItem = getTableFieldItem(column.field);

    if (tableItem.fieldType == 'fileUpload') {
      if (tableItem.isMultipleRequired && tableItem.fileType === 'img/video') {
        return (
          <div>
            {item && item[column.field] && item[column.field].length > 0 ? (
              <div>
                <FileGalleryView
                  item={item}
                  column={column}
                  type={props.customType ? props.customType : props.type}
                />
              </div>
            ) : null}
          </div>
        );
      } else {
        if (tableItem.fileType === 'img/video') {
          return <div className='textElipses'>
            {item && item[column.field] && item[column.field].length > 0 ?
              <Link to={`${config.imgUrl}${props.type.toLowerCase()}/${item[column.field][0]}`} target="_blank" style={tableItem.style}>                {/* {item[column.field][0]} */}
                <img src={`${config.resolutionUrl}${props.type?.split(' ')?.join('')?.toLowerCase()}/sm/${item[column.field]?.[0]}`} />
              </Link> : null
            }
          </div>
        } else {
          return (
            <div className='textElipses'>
              {item[column.field]?.length > 0 && item[column.field].map((img, index) => {
                return (
                  <a key={index} href={`${config.imgUrl}${props.type.toLowerCase()}/${img}`} target='_blank'>
                    <img title={img} src={configImages?.[img?.split('.')?.[img?.split('.')?.length - 1]]} alt="" />
                  </a>
                );
              })
              }
            </div>
          );
        }
      }
    }
    if (tableItem.fieldType == "Link") {
      return <div className='textElipses'>
        <div onClick={() => openFormModal(item, 'view', dirty)} style={tableItem.style} title={item[column.field]}>
          {item[column.field]}
        </div >
      </div >
    }
    else if (tableItem.type === "time" || tableItem.fieldUse === 'dateAndTime') {
      return dateAndTimeFormat(item[column.field], tableItem);
    }
    else if (tableItem.fieldType == "WebSite") {
      return <div className='textElipses'>
        <a href={item[column.field]} title={item[column.field]} target="_blank" style={tableItem.style}>{item[column.field]}</a>
      </div >
    } else if (tableItem.fieldType == "Download") {
      return <div className='textElipses'>
        <a href={item["duplicateFileDownloadUrl"]} title={item[column.field]} target="_blank" style={tableItem.style}>{item[column.field]}</a>
      </div >
    }
    else if ((!tableItem.isMultiSelect && tableItem.fieldType == "dropDown") || tableItem.fieldType == "radio") {
      let mcolor = getColorFromOptions(tableItem.options, item[column.field]);
      return <Badge color={mcolor} className='py-2 px-3'  pill style={tableItem.style}>{item[column.field]}</Badge>
    } 
    else if (tableItem.isMultiSelect && tableItem.fieldType == "dropDown") {
      let mcolor = getColorFromOptions(tableItem.options, item[column.field]);
      // return <Badge color={mcolor} pill style={tableItem.style}> { getArrayBadges(item[column.field]) }</Badge> // for shwoing the multiselect options in a single badge
      return (
        <div>
          {Array.isArray(item[column.field]) && item[column.field].length > 0 ? item[column.field].map((badge, index) => (
            <Badge key={index} color={mcolor} pill style={{ ...tableItem.style, margin: '1px' }}>
              {badge}
            </Badge>
          )) : null}
        </div>
      );
    }
    else if (tableItem.fieldType == "Badge") {
      let mcolor = getColorFromOptions(tableItem.options, item[column.field]);
      return <Badge color={mcolor} pill style={tableItem.style}>{item[column.field]}</Badge>
    } else if (tableItem.fieldType == "Role") {
      let mcolor = getColorFromOptions(tableItem.options, item[column.field]);
      return <Badge color={mcolor} style={tableItem.style}>{item[column.field]}</Badge>
    } else if (tableItem.fieldType === "icon") {
      let mcolor = getColorFromOptions(tableItem.options, item[column.field]);
      let tooltip = getTooltipFromOtptions(tableItem.options, item[column.field]);
      return <FontAwesomeIcon
        style={{ color: mcolor, cursor: tooltip ? 'pointer' : '' }}
        color='white'
        icon={tableItem.iconName}
        data-toggle="tool-tip"
        title={tooltip}
      />
    } else if (tableItem.fieldType === "Array") {
      let val = flattenArray(item[column.field]);
      return <span style={tableItem.style} title={val}>
        {val}
      </span>
    } else if (tableItem.fieldType === "Complex") {
      let data = ObjectbyString(item, column.field)
      return <span style={tableItem.style}>
        {data}
      </span>
    } else if (tableItem.fieldType == "Actions") {
      return (
        <div className='row'
          style={{ justifyContent: 'center' }}>
          <div className='d-flex' style={{flexBasis: 'content'}}>
            {(props.preview) ? <FontAwesomeIcon
              className='genderIconAlignment'
              color='#17a2b8'
              icon='file'
              data-toggle="tool-tip"
              title='Preview'
              style={{ color: '#17a2b8', width: '15', cursor: 'pointer', marginRight: 9 }}
              onClick={() => openPreviewModal(item)} /> : ''}
            {isEditPermission && props.type !== 'SavedSearches' && !tableItem.customActionButton ? <FontAwesomeIcon
              className='genderIconAlignment'
              color='white'
              icon='edit'
              data-toggle="tool-tip" title="Edit"
              style={{ color: '#024a88', width: '15', cursor: 'pointer' }}
              onClick={() => openFormModal(item, 'edit', dirty)} /> : ''}
            {isDeletePermission && !tableItem.customActionButton && (user.role === 'OfficeAdmin' || (item.isAgentCreated && user.role === 'OfficeAgent')) ?
              <FontAwesomeIcon
                className='genderIconAlignment'
                color='white'
                icon='trash-alt'
                data-toggle="tool-tip"
                title="Delete"
                style={{ color: '#bf1725', width: '13', marginLeft: 10, cursor: 'pointer' }}
                onClick={() => setDeleteRecords(item, "Delete")} />
              : null}
            {tableItem.customActions ? tableItem.customActions(item, tableItem, { openFormModal }, openConfirmationModal) : null}
            {props.displayName == 'Clients' ? addNotesCustomAction(item) : props.displayName == 'Tickets' ? addCommentsCustomAction(item): null}
            {(tableItem.customActionButton) ? tableItem.customActionButton(item, openConfirmationModal, openFormModal, setDeleteRecords, dirty) : null}
          </div>
          {/* } */}
        </div >
      )
    } else if (tableItem.fieldType == "relateAutoComplete") {
      if (tableItem.isMultiple) {
        let data = ""
        if (tableItem.searchField && item[column.field] && item[column.field].length > 0) {
          data = item[column.field].map(obj => obj[tableItem.searchField]).join(", ");
        }
        return <span style={tableItem.style} title={data} >
          {tableItem.isClickable ? textEllipses(item, column,data) : data}
        </span >
      } else {
        return tableItem.searchField && item[column.field] && item[column.field][tableItem.searchField] ?
          <span style={tableItem.style} title={item[column.field][tableItem.searchField]} >
            {item[column.field][tableItem.searchField]}
          </span >
          : null;
      }

    } 
    else if (tableItem.fieldType !== "relateAutoComplete" && tableItem.isClickable && tableItem.isClickable == true) {
      return <div className='textElipses'>
        <span onClick={() => openFormModal(item, 'view')} title={item[column.field]} style={tableItem.style}>
          {item[column.field]}
        </span >
      </div >
    }
    else if (tableItem.fieldType == "RACSubField") {
      return tableItem.parentField && item[tableItem.parentField] && item[tableItem.parentField][tableItem.subFieldName] ?
        <span style={tableItem.style} title={item[tableItem.parentField][tableItem.subFieldName]} >
          {item[tableItem.parentField][tableItem.subFieldName]}
        </span >
        : null;
    } else if (tableItem.fieldType == "customType") {
      return customChangeFieldValues(item, column , tableItem);
    } 
    else if (tableItem.fieldType == "checkbox") {
      if (tableItem.multiple) {
        let mcolor = getColorFromOptions(tableItem.options, item[column.field]);
        return (
          <div>
            {Array.isArray(item[column.field]) && item[column.field].length > 0 ?
              item[column.field].map((badge, index) => (
                <Badge key={index} color={mcolor} className='py-2 px-3' pill>{badge}</Badge>
              )) : null}
          </div>
        );
      } else {
        return <span title={item[column.field]} >
          {item[column.field]}
        </span >
      }
    }
    else {
      if ((item[column.field]) && typeof item[column.field] !== 'object') {
        if (column.field == 'date'){
          return <span style={tableItem.style} title={item[column.field]} >
            {getDateCustomField(item[column.field])}
          </span >
        }
        else{
          return <span style={tableItem.style} title={item[column.field]} >
            {item[column.field]}
          </span >
        }
      }
    }
  }

  const clickConfirm = () => {
    closeConfirmationModal();

    let body, apiUrl;
    let method = 'POST'

    if (props.type === 'Agents') {
      apiUrl = 'agents/disableEnable';
      body = {
        "agentIds": [tableRowData._id],
        "status": tableRowData?.status === 'Active' ? 'Inactive' : 'Active',
      };
    } else if (props.type === 'SavedSearches') {
      apiUrl = 'savedSearches/multiUpdate';
      body = {
        "selectedIds": [tableRowData._id],
        "updatedDetails": { "savedSearchStatus": "fullFilled" },
      };
    } else if (props.type === 'ToDoLists') {
      method = 'PUT'
      apiUrl = `toDoLists/${tableRowData._id}`;
      body = {
        "status": tableRowData?.status === 'Completed' ? 'New' : 'Completed',
      };
    }
    return fetchMethodRequest(method, apiUrl, body)
      .then(async (response) => {
        let sessionexpired = localStorage.getItem('sessionexpired');
        if (sessionexpired === "true") {
          setSessionExpiryModal(true);
        }
        if (response && response.respCode) {
          showToasterMessage(response.respMessage, 'success');
          getDataFromServer(filterCriteria);
        } else if (response && response.errorMessage) {
          showToasterMessage(response.errorMessage, 'error');
        }
      }).catch((err) => {
        return err;
      });
  }

  const ObjectbyString = (o, s) => {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  }
  const rowClassName = (item) => {
    if (item.status === 'Blocked') {
      return (
        { 'p-highlightRow': (item.status === 'Blocked') }
      )
    }
  }
  const openPreviewModal = async (item) => {
    setIsPreviewModal(true);
    setEmailTemplateData( item ? item : item);
  }
  const closePreviewModal = () => {
    setIsPreviewModal(false);
    setEmailTemplateData('');
  }
  const closeSendEmailModal = () => {
    setOpenSendEmailModal(false);
    setActions('');
  }
  const handleDateInterval = (startDate, endDate) => {
    if (startDate) {
      setStartDate(startDate);
    }
    if (endDate) {
      setEndDate(endDate);
    }
  }
  // ShowHideColumns Button Click Handler
  //Settings Modal open
  const openShowHideColumnsModal = () => {
    setIsOpenShowHideColumnsModal(true);
  }
  const getSettings = async () => {
    props.getSettings();
  }

  // Called When Clicked on Cancel or Confirm in ShowHideColumnsModal
  const closeShowHideColumnsModal = async (type, columnFields, changedTableFields) => {
    if (type && type === 'confirm' && columnFields) {
      let updatedOrder = [];
      let fields = [...changedTableFields];
      setIsOpenShowHideColumnsModal(false);
      if (viewType === 'grid') {
        fields.map(async item => {
          Object.entries(columnFields).forEach(async ([key, value]) => {
            if (item.field === key) {
              return item.mobile = value;
            }
          });
        })
      }
      if (viewType === 'list') {
        fields.map(async item => {
          Object.entries(columnFields).forEach(async ([key, value]) => {
            if (item.field === key) {
              return item.show = value;
            } else if (item.field + 'Width' === key) {
              return item.width = value
            }
          });
        })
      }
      fields.map(async col => {
        updatedOrder.push(
          {
            field: col.field,
            show: col.show,
            width : col.width,
          }
        )
      });

      /* GetListPreferenceId */
      let setPreferenceValue = {};
      setPreferenceValue[formatPropType(props.type)] = updatedOrder;
      fetchMethodRequest("PUT",`listPreferences/${listPreferences}`,setPreferenceValue)
      setTableFieldsFromListPreferences(updatedOrder);
      updateLocalStorageListPreferences(updatedOrder);
      await getTableFieldsOrder(originalTableFields);
    } else if (type && type === 'confirm') {
      let fields = [...changedTableFields];
      setIsOpenShowHideColumnsModal(false);
      await getTableFieldsOrder(originalTableFields);
    } else {
      setIsOpenShowHideColumnsModal(false);
    }
  }
  const searchInDateRange = () => {
    if (startDate && endDate) {
      let filterCriteria = {};
      let startDate = (moment(startDate).format(config.dateDayMonthFormat));
      let endDate = (moment(endDate).format(config.dateDayMonthFormat));
      filterCriteria['limit'] = 20;
      filterCriteria['page'] = 1;
      filterCriteria['criteria'] = [
        {
          'key': 'date',
          'value': startDate,
          'type': 'gte'
        },
        {
          'key': 'date',
          'value': endDate,
          'type': 'eq'
        }];
      setFilterCriteria(filterCriteria);
      getDataFromServer(filterCriteria);
    } else {
      alert('no dates selected');
    }
  }
  
  const toggle = async (id) => {
    let opId = openId === id ? '' : id;
    setOpenId(opId);
  }

  const print = () => {
    window.print();
  }
  const toogleMultiSelect = async () => {
    setShowMultiSelectDropDown(!showMultiSelectDropDown);
  }
  const onGlobalSearchChange = async (e) => {
    let suggestions = suggestions;
    suggestions[0] = { "label": `Search By First Name ${e.query}` }
    setSuggestions(suggestions);
  }

  const getHeaderGlobalSearch = () => {
    return (
      <>
        {props.globalSearch ?
          <div className='serachAlignment'>
            <div className="p-inputgroup float-right">
              <span className="p-inputgroup-addon">
                < i className="pi pi-search" ></i >
              </span>
              <InputText
                type="search"
                name='globalSearch'
                id='globalSearch'
                value={globalSearch}
                onChange={(e) => onFilterChange(e)}
                placeholder={props.globalSearch ? (props.globalSearch) : 'search'}
                size="25" />
            </div>
          </div>
          : null
        }
      </>)
  }

  const getHeaderPaginator = () => {
    return (
      <>
        {config.paginationPosition == 'top' ?
          <span>{getPaginator()}</span>
          : null}
      </>
    )
  }

  const getHeaderButtons = () => {
    const { t } = props;
    return (
      <>
        <div className='ps-2 flex flex-wrap gap-2'>
          {props.filterRequired ? <span className='mr-3' ref={closeMultiSelectDropdown}>
            <span className='multiSelectDropDownCard '>
              <span onClick={toogleMultiSelect}>
                {t('Filters')}
              </span>
              <span onClick={toogleMultiSelect}>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className='ml-1'
                  color='grey'
                />
              </span>
              {
                showMultiSelectDropDown ?
                  <Card className=' itemsWarperCard' >
                    {
                      tablefieldsToShow && tablefieldsToShow.length > 0 ? tablefieldsToShow.map((item, index) => {
                        return (
                          item.displayInFilter && item.show && < div >
                            <p className='multiSelectDropDownCardUl'>{t(item.header)}</p>
                            <ul className='pl-0'>
                              {
                                item.options && item.options.length > 0 ? item.options.map((optionsItem, optinsIndex) => {
                                  return (
                                    <div className='multiSelectWrapperItems' onClick={() => changeCheckIcon(index, optinsIndex, item.field, optionsItem.value)}>
                                      <span className='chekcIconAdjust'>
                                        <Checkbox
                                          checked={optionsItem.checkIcon}>
                                        </Checkbox>
                                      </span>
                                      <span className='chekcIconAdjustLabel'>
                                        {t(optionsItem.label)}
                                      </span>
                                    </div>
                                  )
                                }) : null
                              }
                            </ul>
                            <hr className='my-0'></hr>
                          </div>
                        )
                      }) : null
                    }
                  </Card>
                  : null}
            </span>

          </span> : null}

          {(screenPermissions?.actions?.length > 0 && actionsTypes && !props.hideActions) ? <Dropdown
            style={{ minWidth: '10%', lineHeight: 1, marginTop: '2px' }}
            className='mr-3'
            name='actions'
            value={actions}
            disabled={selectedRows && selectedRows.length > 0 ? false : true}
            options={actionsTypes}
            placeholder={t('Actions')}
            onChange={(e) => onActionsChange(e, 'dropdownFilter')}
          /> : null}

          {props.printRequried ?
            <Button
              size="small"
              onClick={() => print()}
            >
              <FontAwesomeIcon
                icon='print'
                className='pl-1' size='lg'
                data-toggle="tool-tip" title={t("Print")}
              />
            </Button>
            : null}
          {props.exportRequired &&
            <Button
              size="small"
              onClick={() => exportToCsvModal()}>
              <FontAwesomeIcon
                icon='file'
                data-toggle="tool-tip" title={t("Export To CSV")}
                className='pl-1' size='lg' />
              <CSVLink
                data={exportData}
                filename={`${props.type}.csv`}
                className="hidden text-white"
                ref={csvLinkRef}
                target="_blank" >
              </CSVLink>
            </Button>
          }
          {props.sample ?
            <Button
              size="small"
              onClick={() => bulkUploadMoadal()}>
              <FontAwesomeIcon
                icon='upload'
                size='lg'
                data-toggle="tool-tip" title={t("Bulk Upload")}
                onClick={() => bulkUploadMoadal()} />
            </Button>
            : null}

          {props.gridRequried ?
            <div className="btn-group mb-0" role="group">
              <Button
                outlined={viewType === 'grid' ? false : true}
                size="small"
                style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                onClick={() => changeViewType('grid')}
              >
                <FontAwesomeIcon
                  icon='grip-horizontal'
                  size="sm"
                  data-toggle="tool-tip" title={t("Grid")}
                  onClick={() => changeViewType('grid')}
                />
              </Button>
              <Button
                size="small"
                outlined={viewType === 'list' ? false : true}
                style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                onClick={() => changeViewType('list')}
              >
                <FontAwesomeIcon
                  icon='list'
                  size='lg'
                  data-toggle="tool-tip" title={t("List")}
                  onClick={() => changeViewType('list')}
                />
              </Button>
            </div> : null}

          {props.settingsRequired ?

            <Button
              size="small"
              onClick={() => openShowHideColumnsModal()}
            >
              <FontAwesomeIcon
                icon='cog'
                className='pl-1' size='lg'
                data-toggle="tool-tip" title={t("Settings")}
                onClick={() => openShowHideColumnsModal()}
              />
            </Button> : null}

          {/* Add button */}
          {props.addRequired && props.type === 'Agents' && user.role && user.role !== 'OfficeAgent' ?
            <Button
              size="small"
              className='button-add'
              onClick={() => openFormModal({}, 'add', dirty)}
            >
              <FontAwesomeIcon
                icon='plus'
                size='lg'
                data-toggle="tool-tip" title={t("Add")}
              />
            </Button>
            : props.addRequired && props.type !== 'Agents' ?
              <Button
                size="small"
                className='button-add'
                onClick={() => openFormModal({}, 'add', dirty)}
              >
                <FontAwesomeIcon
                  icon='plus'
                  size='lg'
                  data-toggle="tool-tip" title={t("Add")}
                />
              </Button>
              : null}

          {/* priority  in Issues*/}
          {props.refreshRequired ?
            <Button
              color="secondary"
              size="small"

              onClick={() => getDataFromServer(filterCriteria, 'refresh')}>
              <FontAwesomeIcon
                icon='sync-alt'
                size='lg'
                data-toggle="tool-tip" title={t("Refresh")}
                color={config.templateColor}
                className='refreshIcon pl-1' />
            </Button> : null}
          {props.filterClearRequired ?
            <Button
              color="secondary"
              size="small"
              icon="pi pi-filter-slash"
              title={t("Clear Filter")}
              onClick={() => { setFilters(null); getDataFromServer(filterCriteria, 'clear-all-filters'); }}>
            </Button>
            : null}

          {
            getAdditionalButtons()
          }
        </div>
      </>
    )
  }

  const getScreenHeader = () => {
    const { t } = props;
    return (

      <div className='pb-4'>

        <div className='row my-3'>
          <div className='col-md-5 d-flex flex-wrap'>
            <h4 style={{ paddingLeft: '6px' }}>
              <span className='postionRelative pt-2'>
                <a onClick={() => closeFormModal} className='heading'>
                  {t(props.displayName)}
                </a>{isOpenFormModal && displayViewOfForm === 'screen' ? ` / ${formType} ` : null}
              </span>
            </h4>
          </div>
          {/* <div className='col-md-7 d-flex flex-wrap justify-content-end gap-2'>
            <FilterOperations
              onRef={(ref) => filterOprRef = ref}
              saveCriteria={filterCriteria}
              getDataFromServer={getDataFromServer}
              screenName={props.type}
              defaultCriteria={props.defaultCriteria}
              getCriteriaOfSavedFilt={getCriteriaOfSavedFilt}
              setFilters={setFilters}
              setGlobalSearch={setGlobalSearch}
              filterEvent={filterEvent}
            />
          </div> */}
        </div>

        <div className="row mt-3">
          {/* global Search */}
          <div className='col-8'>
            {(props.dateSearchRequired) ?
              <div className='d-flex gap-2'>
                <RenderIntervalDatePickerField handleDateValueInParent={handleSearchDateInterval}>
                </RenderIntervalDatePickerField>&nbsp;
                <Button className="date-search col-sm-2 mb-0" color="primary" onClick={searchInDateRanges}>{t('dataTable.table_head.search')}</Button>
              </div>
              : null}
          </div>
        </div >

        <div className='screen-header-large'>
          <div className='row mt-1'>
            <div className='col-md-7 d-flex align-items-center'>
              {getHeaderButtons()}
            </div>

            {/* <div className='col-md-2 d-flex flex-wrap align-items-center gap-2'>
              {getHeaderGlobalSearch()}
            </div> */}

            <div className='col-md-5 d-flex flex-wrap align-items-center justify-content-end gap-2'>
              {getHeaderPaginator()}
            </div>
          </div>
        </div>

        {/* <div className='screen-header-small'>
          <div className='row mt-1'>
            <div className='col-md-7 d-flex align-items-center'>
              {getHeaderButtons()}
            </div>

            <div className='col-md-5 d-flex flex-wrap justify-content-end gap-2'>
              {getHeaderGlobalSearch()}
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col-12 d-flex justify-content-end'>
              {getHeaderPaginator()}
            </div>
          </div>
        </div> */}

      </div >
    )
  }

 const onGlobalSerachFilterChange = async (event) => {
    if (event) {
      await onFilterChange(event)
    }

  }

  const getCardHeader = () => {
    return (
      // <CardHeader className='cardHeader'>
      <div className="row m-0">
        {/* table name */}
        <div className='col-3 pl-0 pt-2'>
          <h4><b>{props.type}{props.type == 'Activiti' ? 'es' : ''}</b></h4>
        </div>
        <span className='col-9 text-right'>
          <Row>
            {/* Export to csv */}
            {/* <Button color="primary"
                    className='p-1 ml-auto'
                    size={'sm'} onClick={getDataToExport}>
                    <FontAwesomeIcon
                      icon='file'
                      data-toggle="tool-tip" title="Export To CSV"
                      className='pl-1' size='lg' />
                  </Button>
                  <CSVLink
                    data={exportData}
                    filename={`${props.type}.csv`}
                    className="hidden text-white"
                    ref={(r) => csvLinkRef = r}
                    target="_blank" >
                  </CSVLink> */}
            {/* {props.sample ?
              <Button color="primary"
                size="sm"
                className="p-1"
                onClick={bulkUploadMoadal}>
                <FontAwesomeIcon
                  icon='upload'
                  className='pl-1' size='lg'
                  data-toggle="tool-tip" title="Bulk Upload"
                  onClick={bulkUploadMoadal} />
              </Button>
              : null} */}
            {/* Add Settings Button */}
            {props.settingsRequired ?
              <Button 
              raised 
                size="sm"
                className="p-1 ml-auto mt-1"
                onClick={() => openShowHideColumnsModal()}
              >
                <FontAwesomeIcon
                  icon='cog'
                  className='pl-1' size='lg'
                  data-toggle="tool-tip" title="Settings"
                  onClick={() => openShowHideColumnsModal()}
                />
              </Button>
              : null}
            {/* Add button */}
            {props.addRequired ?
              <a href={`/create_${props.routeTo}`} >
                <Button color="primary"
                  size="sm"
                  className="p-1 ml-auto"
                // onClick={() => openFormModal('add')}
                >
                  <FontAwesomeIcon
                    icon='plus'
                    className='pl-1' size='lg'
                    data-toggle="tool-tip" title="Add"
                  //  onClick={() => openFormModal('add')}
                  />
                </Button></a>
              : null}
            {/* priority  in Issues*/}
            <Button
              color="secondary"
              size="sm"
              // className="p-1 ml-auto"
              className={props.addRequired ? "p-1" : 'p-1 ml-auto'}
              onClick={() => getDataFromServer(filterCriteria, 'refresh')}>
              <FontAwesomeIcon
                icon='sync-alt'
                size='lg'
                data-toggle="tool-tip" title="Refresh"
                color={config.templateColor}
                className='refreshIcon pl-1' />
            </Button>
          </Row>
        </span>

      </div>
      // </CardHeader>
    )
  }
  const cancelUserPwdResetModal = async () => {
    setOpenUserPasswordResetModal(false);
    setActions('');
  }
  const handleSearchDateInterval = (startDate, endDate) => {
    if (startDate) {
      setStartDate(startDate);
    }
    if (endDate) {
      setEndDate(endDate);
    }
  }

  const searchInDateRanges = () => {
    if (startDate && endDate) {
      let filterCriteria = {};
      let startingDate = (moment(startDate).format('YYYY-MM-DD'));
      let endingDate = (moment(endDate).format('YYYY-MM-DD'));
      filterCriteria['limit'] = 20;
      filterCriteria['page'] = 1;
      filterCriteria['criteria'] = [
        { 'key': searchInDateRangeField, 'value': startingDate, 'type': 'gte' },
        { 'key': searchInDateRangeField, 'value': endingDate, 'type': 'lte' }];
      filterCriteria['isDateSearch'] = true;
      getDataFromServer(filterCriteria);
    }
    else {
      alert('no dates selected');
    }
  }

  const onRowEditComplete = async (e) => {
    let _products = [...allUsersData];
    let { newData, index } = e;

    _products[index] = newData;
    setAllUsersData(_products);

    let dateTypes = formFields.filter(item => item.type === 'date')
    for (const key in newData) {
      if (dateTypes.find(item => item.name === key)) {
        newData[key] = moment(newData[key], 'DD-MM-YYYY').toDate();
      }
    }
    delete newData.created;
    delete newData.updated;
    return fetchMethodRequest('PUT', `${props.apiUrl}/${newData._id}`, newData)
      .then(async (response) => {
        if (response && response.respCode) {
          await getDataFromServer(filterCriteria);
          showToasterMessage(response.respMessage, 'success');
        } else if (response && response.errorMessage) {
          showToasterMessage(response.errorMessage, 'error');
        }
      }).catch((err) => {
        return err;
      });

  };

  const getValidationRules = (fieldConfigs) => {
    return fieldConfigs.reduce((rules, field) => {
      if (!field.isEditFormHidden && (field.required || field.validationRequired)) {
        rules[field.name] = {
          required: true,
          type: field.type,
          regexPattern: field.regexPattern || null,
          minLength: field.regexData?.minLengthText ? parseInt(field.regexData.minLengthText) : null,
          maxLength: field.regexData?.maxLengthText ? parseInt(field.regexData.maxLengthText) : null
        };
      }
      return rules;
    }, {});
  };

  const validateField = (field, value) => {
    const rules = validationRules[field];

    if (rules.required && (!value || value.trim() === '')) {
      return false;
    }
    if (rules.regexPattern) {
      const regex = new RegExp(rules.regexPattern);
      if (!regex.test(value)) {
        return false;
      }
    }
    if (rules.minLength && value.length < rules.minLength) {
      return false;
    }
    if (rules.maxLength && value.length > rules.maxLength) {
      return false;
    }

    return true;
  };

  const validationRules = getValidationRules(formFields);

  const rowEditValidator = (data) => {
    for (let field in validationRules) {
      if (!validateField(field, data[field])) {
        showToasterMessage(`Please enter valid ${field}`, 'error');
        return false;
      }
    }
    return true;
  }

  const getFields = (opt, item, i) => {
    const field = formFields.find(field => field.name === item?.field);
    return (
      <div>
        <DataTableEditor
          options={opt}
          item={field}
        />
      </div>
    )
  }

  const getColumns = (e, d) => {
    const { t } = props
    if (tablefieldsToShow && tablefieldsToShow.length > 0) {
      const columns = tablefieldsToShow.map((item, i) => {
        if(item.type && item.type == "relateAutoComplete") item.filter = true;
        return (
          item.show && (
            <Column key={item.field + i}
              style={{
                maxwidth: item.width,
              }}
              bodyStyle={item.capitalizeTableText ? {
                maxWidth: item.width,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textTransform: 'capitalize',
                textAlign: item.textAlign
              } : {
                maxWidth: item.width,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textAlign: item.textAlign
              }}
            field={item.field}
            editRequired={e}
            deleteRequired={d}
            header={t(item.header)}
            changeFieldName={item.changeFieldName}
            body={changeFieldValues}
            headerStyle={{
              padding: 4, fontWeight: 500, maxWidth: item.width, fontSize: 13,
            }}
            filter={item.filter ? item.filter : false}
            dataType={item.type == "date" ? "date" : item.type == "number" ? "numeric" : "text"}
            showFilterMatchModes={item.type == "dropDown" || item.type == "radio" || item.type == 'checkbox' ? false : true}
            showClearButton={true}
            filterClear={filterClearTemplate} 
            showApplyButton={(item.type=="dropDown" || item.type=='date' || item.type=='radio') ? false : true}
            filterMatchMode={(item.type == "dropDown" || item.type == 'date' || item.type == 'radio' || item.type == "number") ? '' : "contains"}
            // showFilterMenu={false}
            sortable={item.sortable ? true : false}
            // filterPlaceholder={item.placeholder ? item.placeholder : 'search'}            
            filterElement={item.filterElement ?
             multiSelectFilterTemplate            
              
              
              : item["type"] == "date" ? 
              dateFilterTemplate
            
               : null
            }
            filterPlaceholder={item.filterPlaceholder ? item.filterPlaceholder : null}
            selectionMode={item.selectionMode}
            editor={(options) => getFields(options, item, i)}
            />
          )
        );
      });

      const hasActionsFieldType = tablefieldsToShow.some(item => item.fieldType === "Actions");
      if (hasActionsFieldType === false && props.displayActionsInTable === 'inline' && isEditPermission) {
        columns.push(
          <Column
            key="rowEditor"
            header={'Actions'}
            rowEditor
            headerStyle={{ width: "10%", minWidth: "8rem" }}
            bodyStyle={{ textAlign: "center" }}
          />
        );
      }
      return columns;
    }
  }

  const filterClearTemplate = (options) => {
    const handleClearFilters = (e) => {
      options.filterClearCallback(e);
      getDataFromServer(filterCriteria, 'clearFilter', options);
    };

    return (
      <Button aria-label="Clear" type="button" className="p-button p-component p-button-outlined p-button-sm"
        onClick={handleClearFilters} style={{ margin: '3px' }}>
        <span className="p-button-label p-c">Clear</span>
      </Button>
    );
  };

  const multiSelectFilterTemplate = (options) => {
    let filterOptions = [];
    filterOptions = getMultiSelectOptions(options);
    return <MultiSelect value={options.value} options={filterOptions} maxSelectedLabels={1} optionallabel="label"
            onChange={(e) => options.filterApplyCallback(e.value, options.index)} placeholder="Any"
            />;  
  };

  const getMultiSelectOptions = (options) => {
    let tableFields = props.getTableFields();
    let i = tableFields.findIndex((col) => col.field === options.field);
    if(i > -1 && tableFields[i].filterElement){
      return tableFields[i].filterElement;
    }else {
      return [];
    }
  }

  const dateFilterTemplate = (options) => {
    return <Calendar value={options.value} showButtonBar selectionMode="range" 
            onChange={(e) => options.filterApplyCallback(e.value, options.index)}
            />;
  };  

  const getDataTable = () => {   
    

    return (
      <DataTable
        rowClassName={rowClassName}
        // ref={(el) => dt = el}
        value={allUsersData}
        totalRecords={totalRecordsLength}
        paginator={false}
        lazy={true}
        size="small"
        resizableColumns={true}
        columnResizeMode="expand"
        onSort={sortChange}
        sortField={sortField}
        sortOrder={sortOrder}
        // globalFilter={globalFilter}
        filters={filters}
        onFilter={onFilterColumns}
        scrollable={true}
        // selection={false}
        onSelectionChange={e => onSelectRowsUpdate(e)}
        scrollHeight='1000px'
        // style={{ marginTop: 0 }}
        emptyMessage={configMessages.noRecords}
        // sortMode="single"
        // sortField="fname"
        // sortOrder={-1}
        selection={selectedRows}       
        selectionMode={'checkbox'}
        metaKeySelection={false}
        loading={isLoading}
        style={allUsersData && allUsersData.length == 0 ?
          { textAlign: 'center' }
          : null}
        // filterDisplay="row"
        editMode="row"
        onRowEditComplete={onRowEditComplete}
        rowEditValidator={rowEditValidator}
      >
        {getColumns(props.editRequired, props.deleteRequired)}
      </DataTable>
    )
  }
  
  const getTabInfo = () => {
    return null;
  }

  const getPaginator = () => {
    // if (isUifilterAndSort) {
    //   return null
    // } else {
      return (
        <PaginatorComponent
          totalRecords={totalRecordsLength}
          first={first}
          rows={rows}
          onPageChange={onPageChange}
          isWeb={true}
        />
      )
    // }
  }

  //onChange function for checkboxes in the gridView
  const onSelectRows = (e, item) => {
    const value = item._id;
    const isChecked = e.target.checked;

    let updatedCheckValue;
    let updatedSelectedRows;

    if (isChecked) {
      updatedCheckValue = [...checkValue, value];
      updatedSelectedRows = [...selectedRows, item];
    } else {
      updatedCheckValue = checkValue.filter(id => id !== value);
      updatedSelectedRows = selectedRows.filter(row => row._id !== value);
    }
    setCheckValue(updatedCheckValue);
    setSelectedRows(updatedSelectedRows);
    getActions(updatedSelectedRows);
  }

  //getGridView
  const getGridView = () => {
    return (
      <div className='row ml-lg-0 mt-2 mx-0'>
        {
          allUsersData && allUsersData.length > 0 ?
            allUsersData.map((item, i) => {
              return (
                <div className='col-sm-6 col-md-4 col-lg-3 px-1 mb-2' key={i}>
                  <Card className='pb-2' >
                    <CardBody className='tableCardBody'>
                      <div className='d-flex justify-content-between'>
                        <span>
                          {<Checkbox
                            onChange={e => onSelectRows(e, item)}
                            checked={checkValue.includes(item._id)}
                          />}
                        </span>
                        <span>
                          {isEditPermission && props.type !== 'Searches' &&
                            <>
                              <FontAwesomeIcon
                                className='genderIconAlignment'
                                color='white'
                                icon='edit'
                                data-toggle="tool-tip" title="Edit"
                                style={{ color: '#024a88', width: '15', cursor: 'pointer' }}
                                onClick={() => openFormModal(item, 'edit', dirty)} />
                              <FontAwesomeIcon
                                className='genderIconAlignment'
                                color='white'
                                icon='trash-alt'
                                data-toggle="tool-tip"
                                title="Delete"
                                style={{ color: '#bf1725', width: '13', marginLeft: 10, cursor: 'pointer' }}
                                onClick={() => setDeleteRecords(item, "Delete")} />
                            </>
                          }
                        </span>
                      </div>
                      <div>
                        {
                          tablefieldsToShow && tablefieldsToShow.length && tablefieldsToShow.length > 0 ?
                            tablefieldsToShow.map((elememt, index) => {
                              if (item[elememt.field]) {
                                return (
                                  <div>
                                    {
                                      elememt.fieldType === 'Link' ?
                                        <div>
                                          <span className='grid_header'>{elememt.header + ' : '}</span>
                                          <span
                                            style={elememt.style}
                                            onClick={() => openFormModal(item, 'view', dirty)}>
                                            <b>{item[elememt.field]}</b>
                                          </span>
                                        </div>
                                        : elememt.fieldType === 'Badge' ?
                                          <div style={elememt.style}>
                                            <span className='grid_header'>{elememt.header + ' : '}</span>
                                            <Badge color={item.status == 'Active' ? 'success' : item.status == 'Inactive' ? 'warning' : item.status == 'Pending' ? 'danger' : item.status == 'Reject' ? 'error' : item.status == 'Completed' ? 'primary' : 'info'}>
                                              {item[elememt.field]}
                                            </Badge>
                                          </div>
                                          : elememt.fieldType === 'fileUpload' ?
                                            <div className='textElipses'>
                                              <span className='grid_header'>{elememt.header + ' : '}</span>
                                              {item[elememt.field]?.length > 0 && item[elememt.field].map((img) => {
                                                let file = typeof img === 'string' ? img : img.name;
                                                let type = props.customType ? props.customType : props.type;
                                                return <Link to={`${config.imgUrl}${type?.toLowerCase()}/${file}`} target="_blank">{file}</Link>
                                              })
                                              }
                                            </div>
                                            : elememt.fieldType === 'Role' ?
                                              <div style={elememt.style}>
                                                <span className='grid_header'>{elememt.header + ' : '}</span>
                                                <Badge pill color='success'>
                                                  {item[elememt.field]}
                                                </Badge>
                                              </div>
                                              : elememt.fieldType === 'Date' ?
                                                <div>
                                                  <span className='grid_header'>{elememt.header + ' : '}</span>
                                                  {dateFormats.formatDate(item[elememt.field], config.dateDayMonthFormat)}
                                                </div>
                                                : elememt.fieldType === 'Time' ?
                                                  <div>
                                                    <span className='grid_header'>{elememt.header + ' : '}</span>
                                                    {dateFormats.formatDate(item[elememt.field], config.timeFormat)} - ${dateFormats.formatDate(item['toTime'], config.timeFormat)}
                                                  </div>
                                                  : elememt.fieldType === 'Array' ?
                                                    <span style={element.style}>
                                                      <span className='grid_header'>{elememt.header + ' : '}</span>
                                                      {flattenArray(item[elememt.field])}
                                                    </span>
                                                    : !elememt.isMultiSelect && elememt.fieldType === "dropDown" ?
                                                      <div style={elememt.style}>
                                                        <span className='grid_header'>{elememt.header + ' : '}</span>
                                                        {getBadgeData(elememt, item[elememt.field])}
                                                      </div>
                                                      : elememt.isMultiSelect && elememt.fieldType === "dropDown" ?
                                                        <div>
                                                          {getMultiSelectBadge(elememt, item[elememt.field])}
                                                        </div>
                                                        : !elememt.isMultiple && elememt.fieldType === "relateAutoComplete" ?
                                                          <span style={elememt.style}>
                                                            <span className='grid_header'>{elememt.header + ' : '}</span>
                                                            {item[elememt.field] && elememt.searchField && item[elememt.field][elememt.searchField] ? item[elememt.field][elememt.searchField] : null}
                                                          </span>
                                                          : elememt.fieldType === "relateAutoComplete" ?
                                                            <div>
                                                              <span className='grid_header'>{elememt.header + ' : '}</span>
                                                              {item[elememt.field] && item[elememt.field].length > 0 ?
                                                                item[elememt.field].map(obj => obj[elememt.searchField]).join(", ")
                                                                : null
                                                              }
                                                            </div>
                                                            : <div style={elememt.style}>
                                                              <span className='grid_header'>{elememt.header + ' : '}</span>
                                                              <span>{item[elememt.field]}</span>
                                                            </div>
                                    }
                                  </div>
                                )
                              }
                            }) : null
                        }
                      </div>
                    </CardBody>
                  </Card>
                </div>
              )
            }) : null
        }
      </div>
    )
  }

  const getBadgeData = (element, value) => {
    let mcolor = getColorFromOptions(element.options, value);
    return (<Badge color={mcolor} pill >{value}</Badge>)
  }

  const getMultiSelectBadge = (element, value) => {
    let mcolor = getColorFromOptions(element.options, value);
    return (
      <div>
        {value.map((badge, index) => (
          <Badge key={index} color={mcolor} pill style={{ margin: '1px' }}>
            {badge}
          </Badge>
        ))}
      </div>
    )
  }

  const getTotalCard = () => {
    if (viewType === 'list' && displayViewOfForm != "sideForm") {
      return (
        <Card className='cardForListMargin' >
          <CardBody className='tableCardBody'>
            {!isOpenFormModal && displayViewOfForm === 'screen' ? getScreenHeader() : displayViewOfForm === 'modal' ? getScreenHeader() : null}
            {getTabInfo()}
            {!isOpenFormModal && displayViewOfForm === 'screen' ? getDataTable() : displayViewOfForm === 'modal' ? getDataTable() : null}
            {isOpenFormModal ? getFormModal() : null}
            {!isOpenFormModal && (displayViewOfForm === 'screen' || displayViewOfForm === 'modal') ? (config.paginationPosition == 'bottom' ? getPaginator() : null) : null}
          </CardBody>
        </Card>
      )
    }
    if (viewType === 'list') {
      if (displayViewOfForm == "sideForm" && props.sideFormLeftOrRight == "right") {
        return (
          <Card className='cardForListMargin' >
            <CardBody className='tableCardBody'>
              {!isOpenFormModal ? getScreenHeader() : null}
              {getTabInfo()}
              {!isOpenFormModal ? getDataTable() : null}
              {isOpenFormModal ? (
                <div className='row'>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "row",
                      overflow: "hidden"
                    }}
                  >
                    <ResizePanel
                      direction="e"
                      style={{ width: "75%", minWidth: "35%" }}
                    >
                      <div style={{ width: "100%", paddingRight: '10px' }}>
                        {getScreenHeader()}
                        {getTabInfo()}
                        {getDataTable()}
                        {config.paginationPosition == 'bottom' ? getPaginator() : null}
                      </div>
                    </ResizePanel>
                    <div style={{ flexGrow: "1", borderLeftStyle: 'solid', borderLeftColor: 'lightgrey', paddingLeft: '10px', paddingRight: '10px', width: '25%' }}>
                      {getFormModal()}
                    </div>
                  </div>
                </div>)
                : null
              }
              {!isOpenFormModal ? (config.paginationPosition == 'bottom' ? getPaginator() : null) : null}
            </CardBody>
          </Card>
        )
      }
      if (displayViewOfForm == "sideForm" && props.sideFormLeftOrRight == "left") {
        return (
          <Card className='cardForListMargin' >
            <CardBody className='tableCardBody'>
              {!isOpenFormModal ? getScreenHeader() : null}
              {getTabInfo()}
              {!isOpenFormModal ? getDataTable() : null}

              {isOpenFormModal ? (
                <div className='row'>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "row",
                      overflow: "hidden"
                    }}
                  >
                    <div style={{ flexGrow: "1", paddingRight: '10px', width: '25%' }}>
                      {getFormModal()}
                    </div>
                    <ResizePanel
                      direction="w"
                      style={{ width: "75%", minWidth: '35%' }}
                    >
                      <div style={{ width: "100%", borderLeftStyle: 'solid', borderLeftColor: 'lightgrey', paddingLeft: '10px' }}>
                        {getScreenHeader()}
                        {getTabInfo()}
                        {getDataTable()}
                        {config.paginationPosition == 'bottom' ? getPaginator() : null}
                      </div>
                    </ResizePanel>
                  </div>
                </div>)
                : null
              }

              {!isOpenFormModal ? (config.paginationPosition == 'bottom' ? getPaginator() : null) : null}
            </CardBody>
          </Card>
        )
      }
    }
    if (viewType === 'grid' && displayViewOfForm != "sideForm") {
      return (
        <div>
          <Card className={'pb-2 cardForGridMargin'} >
            <CardBody className='tableCardBody'>
              {!isOpenFormModal && displayViewOfForm === 'screen' ? getScreenHeader() : displayViewOfForm === 'modal' ? getScreenHeader() : null}
            </CardBody>
          </Card>
          {!isOpenFormModal && displayViewOfForm === 'screen' ? getGridView() : displayViewOfForm === 'modal' ? getGridView() : null}
          {isOpenFormModal ? getFormModal() : null}
          {/* {!isOpenFormModal && (displayViewOfForm === 'screen' || displayViewOfForm === 'modal') ? (config.paginationPosition == 'bottom' ? getPaginator() : null) : null} */}
        </div>
      )
    }
    if (viewType === 'grid') {
      if (displayViewOfForm == "sideForm" && props.sideFormLeftOrRight == "right") {
        return (
          <div>
            <Card
              className={'pb-2 cardForGridMargin'}>
              <CardBody className='tableCardBody pb-0'>
                {!isOpenFormModal ? getScreenHeader() : null}
                {isOpenFormModal ? (
                  <div className='row'>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "row",
                        overflow: "hidden"
                      }}
                    >
                      <ResizePanel
                        direction="e"
                        style={{ width: "75%", minWidth: "35%" }}
                      >
                        <div style={{ width: "100%", paddingRight: '10px' }}>
                          {getScreenHeader()}
                          {getGridView()}
                          {config.paginationPosition == 'bottom' ? getPaginator() : null}
                        </div>
                      </ResizePanel>
                      <div style={{ flexGrow: "1", borderLeftStyle: 'solid', borderLeftColor: 'lightgrey', paddingLeft: '10px', paddingRight: '10px', width: '25%' }}>
                        {getFormModal()}
                      </div>
                    </div>
                  </div>)
                  : null
                }
              </CardBody>
            </Card>
            {!isOpenFormModal ? getGridView() : null}
            {/* {!isOpenFormModal ? (config.paginationPosition == 'bottom' ? getPaginator() : null) : null} */}
          </div>
        )
      }
      if (displayViewOfForm == "sideForm" && props.sideFormLeftOrRight == "left") {
        return (
          <div>
            <Card className='cardForListMargin' >
              <CardBody className='tableCardBody'>
                {!isOpenFormModal ? getScreenHeader() : null}
                {isOpenFormModal ? (
                  <div className='row'>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "row",
                        overflow: "hidden"
                      }}
                    >
                      <div style={{ flexGrow: "1", paddingRight: '10px', width: '25%' }}>
                        {getFormModal()}
                      </div>
                      <ResizePanel
                        direction="w"
                        style={{ width: "75%", minWidth: '35%' }}
                      >
                        <div style={{ width: "100%", borderLeftStyle: 'solid', borderLeftColor: 'lightgrey', paddingLeft: '10px' }}>
                          {getScreenHeader()}
                          {getGridView()}
                          {config.paginationPosition == 'bottom' ? getPaginator() : null}
                        </div>
                      </ResizePanel>
                    </div>
                  </div>)
                  : null
                }
              </CardBody>
            </Card>
            {!isOpenFormModal ? getGridView() : null}
            {/* {!isOpenFormModal ? (config.paginationPosition == 'bottom' ? getPaginator() : null) : null} */}
          </div>
        )
      }
    }
  }

  const getMobileCard = () => {
    return (
      <div>
        <Loader loader={isLoading} />
        {isOpenFormModal ? null : <div className='row mr-0 ml-0'>
          <div className='col-sm-12 px-0 pb-2'>
            <h4><span className='mobileScreenHaederView'><b >{props.type}</b></span>
              <span className='float-right'>
                <Button color="primary"
                  size="sm"
                  className="p-1 ml-auto mt-1 mb-0"
                  onClick={() => openShowHideColumnsModal()}
                >
                  <FontAwesomeIcon
                    icon='cog'
                    className='pl-1' size='lg'
                    data-toggle="tool-tip" title="Settings"
                    onClick={() => openShowHideColumnsModal()}
                  />
                </Button>
              </span>
            </h4>
          </div>
        </div>}
        {isOpenFormModal ? getMobileForm() : getGridView()}
        {!isOpenFormModal ? <PaginatorComponent
          totalRecords={totalRecordsLength}
          first={first}
          rows={rows}
          onPageChange={onPageChange}
          isWeb={false}
        /> : null}
      </div >
    )
  }

  const flattenArray = (arrayVal) => {
    let val = '';
    if (arrayVal) {
      val = JSON.stringify(arrayVal);
      val = val.replace(/"/g, '')
        .replace(/\[/g, '')
        .replace(/]/g, '')
        .replace(/{/g, '')
        .replace(/}/g, '')
        .replace(/,/g, ' , ')
        .replace(/:/g, ' : ');
    }
    return val;
  }
  const getMobileForm = () => {
    return (
      <div className='row'>
        <div className='col-sm-12 px-1'>
          <Card className='pb-0'>
            <CardBody className='tableCardBody pb-0'>
              {/* {getScreenHeader()} */}
              {getFormModal()}
            </CardBody>
          </Card>
        </div>
      </div>
    )
  }
  const getFormFields = () => {
    formModalRef.getFormFields()
  }
  const getFormModal = () => {
   
    return (
      <FormModal
        onRef={(ref) => formModalRef = ref}
        openFormModal={isOpenFormModal}
        tableRowData={tableRowData}
        allUsersData={allUsersData}
        totalRecords={totalRecordsLength}
        first={first}
        rows={rows}
        page={page}
        closeFormModal={closeFormModal}
        // closeRejectModal={closeRejectModal}
        type={props.type}
        tablefieldsToShow={tablefieldsToShow}
        originalTableFields={originalTableFields}
        formType={formType}
        formFields={props.formFields}
        getDataFromServer={getDataFromServer}
        editRequired={props.editRequired}
        getEditInfoKeys={props.getEditInfoKeys}
        filterExtension={props.filterExtension}
        idNotRequired={props.idNotRequired}
        apiUrl={props.formUrl ? props.formUrl : apiUrl}
        role={loginRole}
        tabType={tabType}
        getDoctorPostingFields={props.getDoctorPostingFields}
        categoryNames={props.categoryNames}
        companiesList={props.companiesList}
        getSettings={getSettings}
        filterCriteria={filterCriteria}
        menuList={props.menuList}
        routeTo={props.routeTo}
        displayViewOfForm={displayViewOfForm}
        displayBreadCrumbValue={displayBreadCrumbValue}
        displayBreadCrumbField={displayBreadCrumbField}
        userStatus={userStatus}
        addSelectedRecord={props.addSelectedRecord}
        actionsTypes={actionsTypes}
        entityType={props.entityType}
        setFilterCriteriaForActivities={setFilterCriteriaForActivities}
        getColorFromOptions={getColorFromOptions}
        selectedId={selectedId}
        screenName={props.type}
        setDirty={setDirty}
        isEditPermission={isEditPermission}
        customFormAction={customFormAction}
        getClientSelectModel={props.getClientSelectModel}
        resetSearchFilters={props.resetSearchFilters}
        screenHeader={props.screenHeader}
        customType={props.customType}
        displayName={props.displayName}
      />
    )
  }

  const getViewModal = () => {
    return (
      <ViewModal
        type={props.type}
        openViewModal={openViewModal}
        displayViewOfForm={displayViewOfForm}
        rowData={rowData}
        formFields={props.formFields}
        onRef={(ref) => (viewModalRef = ref)}
        closeViewModal={closeViewModal}
        locationId={locationId}
        drawers={drawers}
      />
    )
  }

  const getOpenShowHideColumnsModal = () => {
    return (
      <ShowHideColumnsModal
        viewType={viewType}
        isOpenShowHideColumnsModal={isOpenShowHideColumnsModal}
        closeShowHideColumnsModal={closeShowHideColumnsModal}
        tableFields={originalTableFields}
        setOriginalTableFields={setOriginalTableFields}
        type={props.type}
      />
    )
  }
  
  const getDeleteRowModal = () => {
    return (
      <DeleteRowModal
        openDeleteModal={openDeleteModal}
        closeDeleteModal={closeDeleteModal}
        selectActions={selectActions}
        deleteSelectedRow={deleteSelectedRow}
        confirmModalText={confirmModalText}
      />
    )
  }
 
 const getPreviewModal = () => {
    return (
      <PreviewModal
        isPreviewModal={isPreviewModal}
        closePreviewModal={closePreviewModal}
        emailTemplateData={emailTemplateData}
        type={props.type}
      />
    )
  }
  const getSendEMailFields = () => {
    return ([{
      required: true,
      value: '',
      type: 'text',
      name: 'email',
      label: 'To',
      id: 'email',
      placeholder: 'Email'
    },
    {
      required: true,
      value: '',
      type: 'text',
      name: 'subject',
      label: 'Subject',
      id: 'subject',
      placeholder: 'Subject'
    },
    // {
    //   required: true,
    //   value: '',
    //   type: 'radio',
    //   name: 'type',
    //   label: 'Type',
    //   id: 'type',
    //   options:[{label:"Custom",value:"Custom"},{label:"Templates",value:"Templates"}],
    //   placeholder: 'Type'
    // },
    {
      required: true,
      value: '',
      type: 'ckeditor',
      name: 'templateText',
      label: 'Body',
      id: 'emailTemplate',
      placeholder: 'name'
    }])
  }
  const getSendEmailModal = () => {
    return (
      <SendEmailModal
        onRef={(ref) => SendEmailModalRef = ref}
        openSendEmailModal={openSendEmailModal}
        closeSendEmailModal={closeSendEmailModal}
        type={props.type}
        item={selectedRows}
        formFields={getSendEMailFields}
        parentFormFields={props.formFields()}
        apiUrl={props.routeTo}
      />
    )
  }
 const getBulkUploadModal = () => {
    return (
      <BulkUploadModal
        // onRef={(ref) => bulkUploadMoadalRef = ref}
        bulkApi={props.bulkApi}
        type={props.type}
        sample={props.sample}
        sampleFilePath={`${bulkSampleFiles[props.apiUrl]}`}
        // sampleFilePath={props.sampleFilePath}
        openBulkUploadModal={openBulkUploadModal}
        closeBulkModal={closeBulkModal}
        reload={getDataFromServer}
        defaultCriteria={props.defaultCriteria}
        filterCriteria={filterCriteria}
      />
    )
  }

  const getExportToCsvModal = () => {
    return (
      <ExportToCsvModal
        tableFields={props.getTableFields()}
        checkedFields={checkedFields}
        openExportToCsvModal={openExportToCsvModal}
        closeExportToCsvModal={closeExportToCsvModal}
        reload={getDataFromServer}
      />
    )
  }

  const getConfirmatioinModal = () => {
    return (
      <ConfirmationModal
        formType={formType}
        onRef={(ref) => (confirmRef = ref)}
        openConfirmationModal={openConfirmationModal}
        closeConfirmationModal={closeConfirmationModal}
        confirm={clickConfirm}
        text={confirmText}
      />
    )
  }

  const closeModal = () => {
    setIsEditConfirmationModal(false);
  }

  const onConfirmation = () => {
    setDirty(false);
    openFormModal(tableRowData, formType, false)
  }

  let getEditPermissionModal = () => {
    return (
      <ConfirmationModal
        openConfirmationModal={isEditConfirmationModal}
        closeConfirmationModal={closeModal}
        confirm={onConfirmation}
        text={'skip the changes'}
      />
    )
  }

  const getSessionExpiryModal = () => {
    return (
      <SessionExpiryModal
        SOpen={sessionExpiryModal}
      />
    )
  }

  const getSelectedRows = () => {
    return selectedRows;
  }


  const getAdditionalButtons = () => {
    return <>
      {
        props.globalShareRequired ? 
        <ShareProperty multipleShare={true} selectedProperties={getSelectedRows}/>
        : null
      }
    </>
  }

  const customChangeFieldValues = (item, column, tableItem) => {
    if (tableItem.customFieldType == 'amount') {
      return <div>
        {typeof item[column.field] === 'number' ? formatIndianCurrency(item[column.field]) : item[column.field]}
      </div >
    }
    else if (tableItem.customFieldType == 'icon') {
      return <div>
        <FontAwesomeIcon
          icon={tableItem.iconName}
          className='cursorPointer mx-2'
          title='Share Via Telegram'
          style={{ color: '#0088CC' }}
        />
        {item[column.field] ? item[column.field] : tableItem.showDefalutValue ? tableItem.showDefalutValue : ''}
      </div >
    }
    else if (props.type === 'SavedSearches' && column.field === 'criteria') {
      const criteria = item[column.field];
      return <span style={tableItem.style} title={criteria?.searchName || ''} >
        {criteria?.searchName || null}
      </span >
    }
    else if (column.field === 'result') {
      return (
        <Link
          to="/showMatchedProperties"
          state={{ propertyID: item._id }}
          style={{ color: '#0e4768', fontWeight: 'bold', textDecoration: 'underline' }}
        >
          {item[column.field]?.length}
        </Link>
      )
    }
    else if (tableItem.customFieldType == 'attachField') {
      if(item[tableItem?.attachField] && item[column.field]){
        return <span>
          {item[column.field] + ' ' + item[tableItem?.attachField]}
        </span>
      }
      return <span>{item[column.field]}</span>
    }
    else if (tableItem.customFieldType === 'parentType'){
      if(tableItem.parentType){
        return <span>{tableItem.parentType(item)}</span>
      }
    }
    else if (column.field === 'addedProperties') {
      let count = item?.addedProperties?.length;
      let propertyIds = item.addedProperties.map(item => item._id);
      return <span style={tableItem.style}>
        <Link
          to="/showMatchedProperties"
          state={{ showPropertiesList: propertyIds }}
          style={{ color: '#0e4768', fontWeight: 'bold' }}
        >
          {count}
        </Link>
      </span >
    }
    else if (tableItem.type === 'ckeditor' && item[column.field]) {
      let updatedValue = updateCkEditorValues(item[column.field]);
      return (
        <LightTooltip title={updatedValue}>
          <div>{updatedValue}</div>
        </LightTooltip>
      );
    }
  }

  const updateLocalStorageListPreferences = (updateOrder) => {
    let listPreferences = localStorage.getItem('listPreferences');
    if(listPreferences){
      listPreferences = JSON.parse(listPreferences);
      if(listPreferences?.columnOrder[formatPropType(props.type)]){
        listPreferences.columnOrder[formatPropType(props.type)] = updateOrder;
      }
      localStorage.setItem('listPreferences', JSON.stringify(listPreferences));
    }
  }

  const getDateCustomField = (field) =>{
    let originalTime = moment.utc(new Date(field)).format(config.customDateFormat);
    return originalTime
  }
  
  const addNotes = async (rowData, selectActions) => {
    setItemRowData(rowData);
    setContextTypeAction(selectActions)
    setOpenAddNotesModal(true)
  }

  const closeAddNotesModal = async () => {
    setOpenAddNotesModal(false);
  }

  const getOpenAddNotesModal = () => {
    return (
      <AddNotesModal
        type={props.type}
        openAddNotesModal={openAddNotesModal}
        rowData={itemRowData}
        closeAddNotesModal={closeAddNotesModal}
        contextTypeAction={contextTypeAction}
      />
    )
  }

  const addNotesCustomAction = (item) => {
    const contextType = props.displayName == 'Clients' ? 'CLIENTS' : "TICKETS"
    return (
      <FontAwesomeIcon
        className='genderIconAlignment'
        color='white'
        icon={props.displayName == 'Clients' ? 'sticky-note' : 'comment'}
        data-toggle="tool-tip"
        title={props.displayName == 'Clients' ? "Add Notes" : "Add Comments"}
        style={{ 
          color: props.displayName == 'Clients' ? '#80caff' : '13ff00', 
          width: '15', marginLeft: 10, cursor: 'pointer' }}
        onClick={() => addNotes(item, contextType)} />
    )
  }

  const addCommentsCustomAction = (item) => {
    return (
      <FontAwesomeIcon
        className='genderIconAlignment'
        color='white'
        icon='comment'
        data-toggle="tool-tip"
        title="Add Comments"
        style={{
          color: '13ff00', width: '15', marginLeft: 10, cursor: 'pointer'
        }}
        onClick={() => { openFormModal(item, 'notes') }} />
    )
  }

  
    return (
      <div >
        {width <= 576 && getMobileCard()}
        {width >= 577 && getTotalCard()}
        {isOpenShowHideColumnsModal ? getOpenShowHideColumnsModal() : null}
        {openDeleteModal ? getDeleteRowModal() : null}
        {openViewModal ? getViewModal() : null}
        {props.sample ? getBulkUploadModal() : null}
        {openExportToCsvModal ? getExportToCsvModal() : null}    
        {isOpenConfirmationModal ? getConfirmatioinModal() : null}
        {sessionExpiryModal ? getSessionExpiryModal() : null}
        {isPreviewModal ? getPreviewModal() : null}
        {redirectToRoute ? <Navigate to={`/${props.routeTo}`} /> : null}
        {openSendEmailModal ? getSendEmailModal() : null}
        {isEditConfirmationModal ? getEditPermissionModal() : null}
        {openAddNotesModal ? getOpenAddNotesModal() : null}
      </div >
    );
  
}

const mapStateToProps = state => {
  return { articles: state.articles };
};
const List = connect(mapStateToProps)(DataTables);

export default withTranslation('common')(DataTables);
