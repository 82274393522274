import moment from "moment";
import config from "./config";

export const PropertyAeraOptions  = [
    {
      label: "Sq.Ft.",
      value: "sqft",
      color: "success",
    },
    {
      label: "Sq.Yards",
      value: "sqyards",
      color: "danger",
    },
    {
      label: "Sq.M.",
      value: "sqm",
      color: "warning",
    }, 
    {
        label: "Aankadam",
        value: "aankadam",
        color: "secondary",
    },
    {
        label: "Guntha",
        value: "guntha",
        color: "success",
    },
    {
        label: "Cents",
        value: "cents",
        color: "danger",
    },
    {
        label: "Acres",
        value: "acres",
        color: "info",
    },
    {
        label: "Hectares",
        value: "hectares",
        color: "dark",
    },
    {
      label: "Grounds",
      value: "grounds",
      color: "info",
    },
    {
        label: "Kanal",
        value: "kanal",
        color: "success",
    },
    {
      label: "Rood",
      value: "rood",
      color: "primary",
    },
    {
      label: "Chataks",
      value: "chataks",
      color: "dark",
    },
    {
      label: "Perch",
      value: "perch",
      color: "light",
    },
    {
      label: "Ares",
      value: "ares",
      color: "danger",
    },
    {
      label: "Biswa",
      value: "biswa",
      color: "warning",
    },
    {
      label: "Bigha",
      value: "bigha",
      color: "secondary",
    },
    {
      label: "Kottah",
      value: "kottah",
      color: "primary",
    },
    {
      label: "Marla",
      value: "marla",
      color: "light",
    },
  ];
  
export const updateCkEditorValues = (values) => {
  values = values?.replace(/-/g, '');
  const tempElement = document.createElement('div');
  tempElement.innerHTML = values;
  return (tempElement.textContent.replace(/<p>/gi, ' ').replace(/<\/p>/gi, ' '));
}

export const dateAndTimeFormat = (timestamp, tableItem) => {
  if (!timestamp || isNaN(new Date(timestamp).getTime())) {
    return null;
  }
  const localDate = moment.utc(timestamp).local();
  let hours = localDate.hours();
  let minutes = localDate.minutes().toString().padStart(2, '0');
  let period = 'AM';

  if (hours >= 12) {
    period = 'PM';

    // Convert 12-hour format to 12 AM/PM format
    if (hours > 12) {
      hours -= 12;
    }
  }
  // Format the time as "hh:mm AM/PM"
  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes} ${period}`;
  const formattedDate = `${localDate.format(config.dateDayMonthFormat)}`
  if (tableItem?.fieldUse === "dateAndTime") {
    return <span style={tableItem.style} title={formattedTime}>
      {formattedDate + ' ' + formattedTime}
    </span>;
  } else {
    return <span style={tableItem?.style} title={formattedTime}>
      {formattedTime}
    </span>;
  }
}